export default {
    id: null,
    transporter_id : "",
    name : "",
    address1 : "",
    address2 : "",
    city : "",
    postal_code : "",
    phone : "",
    fax : "",
    transport_mode : "",
    additional_information : "",
    payment_term : ""
}