export default {
    id: null,
    do_number : null,
    so_number : null,
    delivery_date : null,
    req_arrival_date : null,
    order_type : null,
    origin_id : null,
    dest_id : null,
    client_id : null,
    order_status : null,
    order_qty : null,
    uom : null,
    order_qty_v2 : null,
    uom_v2 : null,
    remark : null
}