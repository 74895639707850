import AppService from "./AppService";

class OptionService{
    URL = 'option';
    
    constructor(){
        
    }

    /**
     * Get option from server
     * @param {*} url 
     * @param {*} params 
     * @returns 
     */
    getOption(url, params){
        const data = { "search": params };
        return AppService.getWithParam(this.URL + url, data);
    }
}

export default new OptionService();