import AuthService from "@/services/AuthService"

export default {
    state: { 
        menu: null,
        menuAccess: null,
    },
    mutations: { 
        set_menu (state, data) {
            state.menu = data
        }, 
        reset_menu (state) {
            state.menu = null
        },

        set_menu_access (state, data) {
            state.menuAccess = data
        },
        reset_menu_access (state) {
            state.menuAccess = null
        },
    },
    getters:{ 
        menu (state){
            return state.menu
        },
        menuAccess (state) {
            return state.menuAccess
        }
    },
    actions:{
        async getMenuAccess({ commit }){
            return new Promise((resolve, reject) => { 
                AuthService.getMenuAccess()
                  .then(response => {
                      if(response.status == 'success'){
                        const data = response.data;
                        commit('set_menu', data.menu)
                        commit('set_menu_access', data.access)
                      }
                      resolve(response)
                  })
                  .catch(err => { 
                    commit('reset_menu')
                    commit('reset_menu_access')
                    reject(err)
                  })
              })
        }
    }
}