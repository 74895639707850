export default {
    id : null,
    name : null,
    email : null,
    password : null,
    role_id : null,
    role_code : null,
    role_desc : null,
    is_active : null,
    use_dashboard : null,
    phone : null,
    gender : null,
    contact_email : null,
    employee_id : null,
    image : null,
    client_id : null,
    is_change_rate : null,
}