<template>
    <div>
        <Panel :title="titleView"  style="margin:0px 1px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <LinkButton v-if="action.add" :plain="true" :iconCls="iconUtil.iconAdd" @click="onAdd()">{{ btnText.add }}</LinkButton>
                <LinkButton v-if="action.edit" :plain="true" :iconCls="iconUtil.iconEdit" @click="onEdit()">{{ btnText.edit }}</LinkButton>
                <LinkButton v-if="action.delete" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDelete()">{{ btnText.delete }}</LinkButton>
                <span class="button-sep"></span> {{ search.text }} : 
                <SearchBox style="width:300px"
                    :placeholder="search.placeholder" 
                    v-model="dataGrid.search"
                    @search="onSearch()">
                    <Addon>
                        <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                    </Addon>
                </SearchBox>
            </div>
            <DataGrid
                    ref="dataGrid"
                    :columnResizing="true"
                    :border="false"
                    :lazy="true"
                    :loading="dataGrid.loading"                                    
                    :data="dataGrid.rows"
                    :pagination="true"
                    :selectionMode="dataGrid.selectionMode"
                    :total="dataGrid.total"
                    :pageSize="dataGrid.pageSize"
                    :pageNumber="dataGrid.pageNumber"
                    :pageLayout="dataGrid.pageLayout" 
                    @pageChange="onPageChange($event)"
                    @sortChange="onSortChange($event)"
                    @rowDblClick="onRowDblClick()"
                    @rowExpand="onRowExpand($event)"
                    >
                
                <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                    <template slot="body" slot-scope="scope">
                        {{scope.rowIndex + 1}}
                    </template>
                </GridColumn>
                <GridColumn :expander="true" width="30"></GridColumn>

                <GridColumn field="service_order_no" title="Service Id" :sortable="true"></GridColumn>
                <GridColumn field="vehicle_desc" title="Vehicle Id" :sortable="true"></GridColumn>
                <GridColumn field="reference" title="Reference" :sortable="true"></GridColumn>
                <GridColumn field="registered_date_desc" title="Registered Date" :sortable="true"></GridColumn>
                <GridColumn field="completion_date_desc" title="Completion Date" :sortable="true"></GridColumn>
                <GridColumn field="total_amount" title="Total Amount" :sortable="true" align="right"></GridColumn>
                <GridColumn field="workshop_desc" title="Workshop Id" :sortable="true"></GridColumn>
                <GridColumn field="service_type_desc" title="Service Type" :sortable="true"></GridColumn>
                <GridColumn field="service_status_desc" title="Service Status" :sortable="true"></GridColumn>
                <template slot="detail" slot-scope="scope">
                    <div style="padding:2px 2px 2px 30px">
                        <DataGrid :data="scope.row.subData" :showFooter="true" style="width: 80%;" :footerData="scope.row.subData.footerData">
                            <GridColumn field="component_desc" title="Service Task" width="150"></GridColumn>
                            <GridColumn field="qty" title="Qty"  width="50" align="right"></GridColumn>
                            <GridColumn field="price" title="Price" width="80" align="right"></GridColumn>
                            <GridColumn field="amount" title="Amount" width="80" align="right"></GridColumn>
                        </DataGrid>
                    </div>
                </template>
            </DataGrid>
        </Panel>

        <!-- Form -->
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'768px', height:'600px'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Service Information</b></legend>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td>Service Id</td>
                                    <td style="width:35%;">
                                        <NumberBox inputId="service_order_no" name="service_order_no" class="f-field-text text-50" v-model="modelNotMapping.service_order_no" :disabled="true" :spinners="false"></NumberBox>
                                    </td>
                                    <td>
                                        Workshop ID <font color="red">*</font>
                                    </td>
                                    <td> 
                                        <ComboBox  :data="workshops" inputId="workshop_id" name="workshop_id" @filterChange="optionWorkshop($event)" class="f-field-text" v-model="model.workshop_id">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.address}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.workshop_id}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Vehicle ID <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="vehicles" inputId="vehicle_id" name="vehicle_id" @filterChange="optionVehicle($event)" class="f-field-text" v-model="model.vehicle_id">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.vehicleType}}</span><br/>
                                                <span style="color:#888">{{scope.row.transporter}} ({{scope.row.status}})</span><br/>
                                                <span style="color:#888">Max Weight : {{scope.row.max_weight}}  Max Volume : {{scope.row.max_volume}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.vehicle_id}}</div>
                                    </td>
                                    <td>Service Type <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="serviceTypes" inputId="service_type" name="service_type" class="f-field-text" v-model="model.service_type"></ComboBox>
                                        <div class="error">{{textError.service_type}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Reference <font color="red">*</font>
                                    </td>
                                    <td> 
                                        <TextBox inputId="reference" name="reference" class="f-field-text" v-model="model.reference"></TextBox>
                                        <div class="error">{{textError.reference}}</div>
                                    </td>
                                    <td>Service Status <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="serviceStatus" inputId="service_status" name="service_status" class="f-field-text" v-model="model.service_status"></ComboBox>
                                        <div class="error">{{textError.service_status}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Odometer <font color="red">*</font></td>
                                    <td>
                                        <NumberBox inputId="odometer" name="odometer" class="f-field-text" v-model="model.odometer" :spinners="false"></NumberBox>
                                        <div class="error">{{textError.odometer}}</div>
                                    </td>
                                    <td>Assigned To <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="mechanics" inputId="assigned_to" name="assigned_to" @filterChange="optionMechanic($event)" class="f-field-text" v-model="model.assigned_to">
                                            <template slot="item" slot-scope="scope">
                                                <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                                <span style="color:#888">{{scope.row.workshop}}</span><br/>
                                            </template>
                                        </ComboBox>
                                        <div class="error">{{textError.assigned_to}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Registered Date  <font color="red">*</font></td>
                                    <td>
                                        <DateBox inputId="registered_date" name="registered_date" class="f-field-text text-70" v-model="model.registered_date" format="dd/MM/yyyy"></DateBox>
                                        <TimePicker inputId="registered_time" name="registered_time" class="f-field-text text-30" v-model="model.registered_time" :hour24="true" :panelStyle="{width:'250px',height:'350px'}"></TimePicker>
                                        <div class="error">{{textError.registered_date}}      {{textError.registered_time}}</div>
                                    </td>
                                    <td>Vat</td>
                                    <td>
                                        <NumberBox inputId="vat" name="vat" class="f-field-text" v-model="model.vat" :spinners="false"></NumberBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Completion Date </td>
                                    <td>
                                        <DateBox inputId="completion_date" name="completion_date" class="f-field-text text-70" v-model="model.completion_date" format="dd/MM/yyyy"></DateBox>
                                        <TimePicker inputId="completion_time" name="completion_time" class="f-field-text text-30" v-model="model.completion_time" :hour24="true" :panelStyle="{width:'250px',height:'350px'}"></TimePicker>
                                    </td>
                                    <td>Remark</td>
                                    <td>
                                        <TextBox inputId="remark" name="remark" class="f-field-text" v-model="model.remark"></TextBox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Service Task</b></legend>
                        <table width="100%" cellspacing="0" cellpadding="2" border="0">
                            <tbody>
                                <tr>
                                    <td style="width:50%;" align="left">
                                        Task :
                                        <ComboBox  :data="serviceTask" inputId="serviceTask" name="serviceTask" @filterChange="optionServiceTask($event)" @selectionChange="onSelectServiceTask($event)" class="f-field-text" v-model="modelInputServiceTask.component_id"></ComboBox>
                                    </td>
                                    <td style="width:15%;padding-left: 10px;" align="left">
                                        Quantity :
                                        <NumberBox inputId="qty" name="qty" class="f-field-text" :spinners="false" v-model="modelInputServiceTask.qty"></NumberBox>
                                    </td>
                                    <td style="width:30%;padding-left: 10px;" align="left">
                                        Price :
                                        <NumberBox inputId="price" name="price" class="f-field-text" :spinners="false" v-model="modelInputServiceTask.price">
                                        </NumberBox>
                                    </td>
                                    <td style="width:5%;padding-left: 10px;" align="left">
                                        &nbsp;&nbsp;
                                        <LinkButton :iconCls="iconUtil.iconAdd" style="border:none;" @click="addServiceTask()"></LinkButton>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <Panel>
                            <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                {{ search.text }} : 
                                <SearchBox style="width:300px"
                                    :placeholder="search.placeholder" 
                                    v-model="dataGridServiceTask.search"
                                    @search="onSearchDetail()">
                                    <Addon>
                                        <span v-if="dataGridServiceTask.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGridServiceTask.search=null;onSearchDetail();"></span>
                                    </Addon>
                                </SearchBox>
                                <LinkButton v-if="action.delete" :plain="true" :iconCls="iconUtil.iconDelete" @click="onDeleteServiceTask()">{{ btnText.delete }}</LinkButton>
                            </div>

                            <DataGrid
                                ref="dataGridServiceTask"
                                :columnResizing="true"
                                :border="false"
                                :loading="dataGridServiceTask.loading"                                    
                                :data="dataGridServiceTask.rows"
                                :pagination="true"
                                :selectionMode="dataGridServiceTask.selectionMode"
                                :total="dataGridServiceTask.total"
                                :pageSize="dataGridServiceTask.pageSize"
                                :pageNumber="dataGridServiceTask.pageNumber"
                                :pageLayout="dataGridServiceTask.pageLayout"
                                :footerData="dataGridServiceTask.footerData"
                                :showFooter="true"
                                style="width: 694px;height:386px">
                            <GridColumn field="component_desc" title="Service Task" width="150"></GridColumn>
                            <GridColumn field="qty" title="Qty"  width="50" align="right"></GridColumn>
                            <GridColumn field="price" title="Price" width="80" align="right"></GridColumn>
                            <GridColumn field="amount" title="Amount" width="80" align="right"></GridColumn>
                        </DataGrid>
                        </Panel>
                    </fieldset>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSave()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
    </div>
    
</template>

<script>
import NotificationComponent from '@/components/NotificationComponent.vue';
import ServiceOrder from '@/models/ServiceOrder';
import FleetService from '@/services/FleetService';
import ModelUtil from '@/utils/ModelUtil';
import InformationUtil from '@/utils/InformationUtil';
import AccessUtil from '@/utils/AccessUtil';
import IconUtil from '@/utils/IconUtil';
import GlobalUtil from '@/utils/GlobalUtil';
import OptionService from '@/services/OptionService';

export default {
    name: 'ServiceOrderView',
    components : {
        NotificationComponent
    },
    data() {
        return {
            titleView : InformationUtil.serviceOrder + "s",
            titleMessage : InformationUtil.serviceOrder,
            iconUtil : IconUtil,

            titleForm :{
                add : InformationUtil.addText,
                edit : InformationUtil.editText
            },

            iconForm :{
                add : IconUtil.iconAdd,
                edit : IconUtil.iconEdit
            },
            
            action : {
                view : false,
                add : false,
                edit : false,
                delete : false
            },

            btnText :{
                refresh : InformationUtil.refreshText,
                add : InformationUtil.addText,
                edit : InformationUtil.editText,
                delete : InformationUtil.removeText,
                save : InformationUtil.saveText,
                cancel : InformationUtil.cancelText,
                search : InformationUtil.searchText
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : "",

                footerData : []
            },

            dataGridServiceTask : {
                loading: false,
                rows : [],
                rowsTemp : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : "",

                // footer
                footerData : [
                    { price: "Sub Total:", amount: 0 },
                    { price: "Total VAT:", amount: 0 },
                    { price: "Total Amount:", amount: 0 }
                ]
            },

            // form
            title: '',
            icon: '',
            model: ServiceOrder,
            disabled : false,
            disableBtnSave : false,

            modelNotMapping : {
                service_order_no : "",
            },

            modelInputServiceTask : {
                component_id : "",
                component_desc : "",
                qty : 0,
                price : 0,
                amount : 0
            },

            modelTotal : {
                sub_total : 0,
                total_vat : 0,
                total_amount : 0
            },

            // validation
            rules: {
                vehicle_id: 'required',
                reference : 'required',
                odometer : 'required',
                client_id: 'required',
                driver_id: 'required',
                location: 'required',
                registered_date: 'required',
                registered_time: 'required',
                workshop_id: 'required',
                service_type: 'required',
                service_status : 'required',
                assigned_to : 'required',
            },
            errors: {},
            textError :{
                vehicle_id: '',
                reference : '',
                odometer : '',
                client_id: '',
                driver_id: '',
                location: '',
                registered_date: '',
                registered_time: '',
                workshop_id: '',
                service_type: '',
                service_status : '',
                assigned_to : '',
            },

            // combobox
            mechanics : [],
            workshops : [],
            vehicles : [],
            serviceTask : [],
            serviceTypes : [
                { value: 1, text : "INTERIM CAR SERVICE" },
                { value: 2, text : "FULL CAR SERVICE" },
                { value: 3, text : "MAJOR CAR SERVICE" }
            ],
            serviceStatus : [
                { value: 2, text : "QUEUED" },
                { value: 3, text : "IN PROGRESS" },
                { value: 4, text : "COMPLETED" },
                { value: 1, text : "REJECTED" },
            ]
        }
    },
    methods:{
        onTabSelected(){
            this.dataGrid.search = null;
            this.onRefresh();
        },
        getError(name) {
            return this.errors[name] && this.errors[name].length
            ? this.errors[name][0]
            : null;
        },
        onAdd(){
            ModelUtil.resetModel(this.textError);
            // method for add data
            this.disabled = false;
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);

            this.model = ModelUtil.resetModel(this.model);
            this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
            this.modelInputServiceTask = ModelUtil.resetModelByType(this.modelInputServiceTask);
            this.modelInputServiceTask.component_id = null;
            this.modelTotal = ModelUtil.resetModelByType(this.modelTotal);

            
            this.onCallAllOption();
            
            // set default 
            this.model.service_type = 1;
            this.model.service_status = 2;
            this.model.registered_date = new Date();
            this.modelNotMapping.service_order_no = parseInt(this.modelNotMapping.service_order_no);
            this.model.odometer = parseInt(this.model.odometer);
            this.model.vat = parseInt(this.model.vat);
            this.dataGridServiceTask.rows = [];
            this.dataGridServiceTask.rowsTemp = this.dataGridServiceTask.rows;
            this.onSumDataTotal()

            this.title = this.titleForm.add;
            this.icon = this.iconForm.add;
            this.$refs.dlg.open();
        },
        onEdit(){
            ModelUtil.resetModel(this.textError);
            // method for get data to edit
            if(this.$refs.dataGrid.selectedRows.length > 0){
                this.disabled = true;
                this.title = this.titleForm.edit;
                this.icon = this.iconForm.edit;
                this.onCallAllOption();

                // mapping combobox
                this.mechanics = [{value: this.$refs.dataGrid.selectedRows[0].assigned_to, text: this.$refs.dataGrid.selectedRows[0].assigned_to_desc}]
                this.workshops = [{value: this.$refs.dataGrid.selectedRows[0].workshop_id, text: this.$refs.dataGrid.selectedRows[0].workshop_desc}]
                this.vehicles = [{value: this.$refs.dataGrid.selectedRows[0].vehicle_id, text: this.$refs.dataGrid.selectedRows[0].vehicle_desc}]

                this.model = ModelUtil.resetModel(this.model);
                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                this.modelInputServiceTask = ModelUtil.resetModelByType(this.modelInputServiceTask);
                this.modelInputServiceTask.component_id = null;
                this.modelTotal = ModelUtil.resetModelByType(this.modelTotal);
                this.dataGridServiceTask.rows = [];
                this.dataGridServiceTask.rowsTemp = this.dataGridServiceTask.rows;

                this.model = ModelUtil.mapModel(this.model, this.$refs.dataGrid.selectedRows[0])
                this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, this.$refs.dataGrid.selectedRows[0])
                this.dataGridServiceTask.rows = JSON.parse(this.$refs.dataGrid.selectedRows[0].task_entries);
                this.dataGridServiceTask.rowsTemp = this.dataGridServiceTask.rows;
                this.onSumDataTotal();                

                // mapping data
                this.model.registered_date = new Date(this.model.registered_date);
                this.model.completion_date = this.model.completion_date != null ? new Date(this.model.completion_date) : null;
                this.model.odometer = parseInt(this.model.odometer);
                this.model.vat = parseInt(this.model.vat);

                this.$refs.dlg.open();
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
        },
        onDelete(){
            if(this.$refs.dataGrid.selectedRows.length > 0){

                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGrid.loading = true;
                            FleetService.deleteData("/serviceOrder", this.$refs.dataGrid.selectedRows[0].id)
                                .then(() => {
                                    this.dataGrid.loading = false;
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                }).catch((err) => {
                                    this.$refs.notification.error(err.data.message);
                                });
                            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]) 
                        }
                    }
                })
                
            }else{
                this.$refs.notification.centerTop(InformationUtil.selectRow);
            }
            
        },
        onSave(){
            ModelUtil.resetModel(this.textError);
            // validation for task entries
            if(this.dataGridServiceTask.rows.length == 0){
                this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: "Service Task is required"
                                    });
                return;
            }

            this.$refs.form.validate((valid) => {
                if(!valid){
                    this.disableBtnSave = true; 

                    // mapping data
                    this.model.task_entries = this.dataGridServiceTask.rowsTemp;
                    this.onSumDataTotal();
                    this.model.sub_total = this.modelTotal.sub_total;
                    this.model.total_vat = this.modelTotal.total_vat;
                    this.model.total_amount = this.modelTotal.total_amount;

                    this.model.registered_date = new Date(this.$moment(this.model.registered_date).format('YYYY-MM-DD'));
                    this.model.completion_date = this.model.completion_date != null ? new Date(this.$moment(this.model.completion_date).format('YYYY-MM-DD')) : null;

                    if(this.model.id == null){
                        // action add data
                        FleetService.addData("/serviceOrder", this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.add);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }                               
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });

                    }else{
                        // action update data
                        FleetService.updateData("/serviceOrder", this.model.id, this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                                    this.loadData();
                                    this.$refs.dlg.close();
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });
                    }
                    this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0])
                } else {
                    for(let key in valid){
                        this.textError[key] = this.getError(key);
                    }
                }
            });
            
        },
        
        // handle data grid
        onRowExpand(row){
            if ( ! row.subData ){
                row.subData = JSON.parse(row.task_entries);
                row.subData.footerData = [
                    { price: "Sub Total:", amount: row.sub_total },
                    { price: "Total VAT:", amount: row.total_vat },
                    { price: "Total Amount:", amount: row.total_amount }
                ];
            }
        },
        onRowDblClick(){
            if(this.action.edit){
                this.onEdit();
            }
        },
        async onRefresh(){
            this.dataGrid.pageNumber = 1;       
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        onSearch(){
            this.loadData();
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            return FleetService.getData('/serviceOrder', this.dataGrid)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                    }
                    
                    this.dataGrid.loading = false;
                });
        },

        // data grid detail
        onSelectServiceTask(event){
            if(event != null && event.text != undefined){
                this.modelInputServiceTask.component_desc = event.text;
            }
        },
        onDeleteServiceTask(){
            if(this.$refs.dataGridServiceTask.selectedRows.length > 0){
                this.$messager.confirm({
                    title: 'Confirm',
                    msg: InformationUtil.confirmDelete,
                    result: (r) => {
                        if (r){
                            this.dataGridServiceTask.loading = true;
                            // find index
                            let index = -1;
                            for(let i = 0; i < this.dataGridServiceTask.rows.length; i++){
                                if(this.dataGridServiceTask.rows[i].component_desc == this.$refs.dataGridServiceTask.selectedRows[0].component_desc){
                                    index = i;
                                    break;
                                }
                            }
                            if(index > -1) this.dataGridServiceTask.rows.splice(index, 1);
                            this.dataGridServiceTask.loading = false;
                            this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                            this.$refs.dataGridServiceTask.unselectRow(this.$refs.dataGridServiceTask.selectedRows[0]);
                            this.onSumDataTotal();
                            this.dataGridServiceTask.rowsTemp = this.dataGridServiceTask.rows;
                        }
                    }
                })
            }else{
                this.$messager.alert({
                    title: "Info",
                    icon: "info",
                    msg: InformationUtil.selectRow
                });
            }
        },
        addServiceTask(){
            // validation
            if(this.modelInputServiceTask.component_desc == null || this.modelInputServiceTask.component_desc == ""){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Task is required"
                });
                return;
            }

            if(this.modelInputServiceTask.qty == null || this.modelInputServiceTask.qty == "" || this.modelInputServiceTask.qty == 0){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Qty is required"
                });
                return;
            }

            if(this.modelInputServiceTask.price == null || this.modelInputServiceTask.price == "" || this.modelInputServiceTask.price == 0){
                this.$messager.alert({
                    title: "Error",
                    icon: "error",
                    msg: "Price is required"
                });
                return;
            }

            let data = {
                component_id : this.modelInputServiceTask.component_id,
                component_desc : this.modelInputServiceTask.component_desc,
                qty : parseInt(this.modelInputServiceTask.qty),
                price : parseInt(this.modelInputServiceTask.price),
                amount : parseInt(this.modelInputServiceTask.qty) * parseInt(this.modelInputServiceTask.price)
            }
            this.dataGridServiceTask.rows.push(data);
            this.dataGridServiceTask.rowsTemp = this.dataGridServiceTask.rows;
            this.dataGridServiceTask.total = this.dataGridServiceTask.rows.length;
            this.onSumDataTotal();

            ModelUtil.resetModelByType(this.modelInputServiceTask);
            this.modelInputServiceTask.component_id = null;
        },
        onSearchDetail(){
            let search = this.dataGridServiceTask.search;
            let rows = this.dataGridServiceTask.rowsTemp;
            let result = [];
            if(search != null && search != ""){
                for(let i = 0; i < rows.length; i++){
                    if(rows[i].component_desc.toLowerCase().indexOf(search.toLowerCase()) > -1){
                        result.push(rows[i]);
                    }
                }
                this.dataGridServiceTask.rows = result;
            }else{
                this.dataGridServiceTask.rows = this.dataGridServiceTask.rowsTemp;
            }
        },
        onSumDataTotal(){
            let amount = 0;
            this.dataGridServiceTask.rowsTemp.forEach((item) => {
                amount += item.amount;
            });
            this.modelTotal.sub_total = amount;
            this.modelTotal.total_vat = this.model.vat > 0 ? Math.round(amount * this.model.vat / 100) : 0;
            this.modelTotal.total_amount = this.modelTotal.sub_total + this.modelTotal.total_vat;

            this.dataGridServiceTask.footerData[0].amount = this.modelTotal.sub_total;
            this.dataGridServiceTask.footerData[1].amount = this.modelTotal.total_vat;
            this.dataGridServiceTask.footerData[2].amount = this.modelTotal.total_amount;
        },

        // combobox
        async optionWorkshop(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/workshop", search)
                .then((response) => {
                    this.workshops = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },
        async optionMechanic(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/mechanic", search)
                .then((response) => {
                    this.mechanics = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },

        async optionVehicle(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/vehicle", search)
                .then((response) => {
                    this.vehicles = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },

        async optionServiceTask(event){
            let search = event == null ? "" : event.filterValue;
            return await OptionService.getOption("/serviceTask", search)
                .then((response) => {
                    this.serviceTask = response.data;
                }).catch((error) => {
                    this.$refs.notification.error(error);
                });
        },

        onCallAllOption(){
            this.optionWorkshop();
            this.optionMechanic();
            this.optionVehicle();
            this.optionServiceTask();
        },

    },
    async mounted(){
        this.action = AccessUtil.getAccessAction('service_order');
        await this.loadData();
        this.$refs.notification.success(InformationUtil.loadGrid + " " + this.dataGrid.total + " " + InformationUtil.successLoadGrid);
    },
    created(){
        GlobalUtil.removeWindow();
    }
};
</script>