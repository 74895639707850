class GlobalUtil {
    removeWindow() {
        for(let countWindow = 0; countWindow < 4; countWindow++) {
            // delete window
            let window = document.querySelector('body').getElementsByClassName('panel f-column window window-shadow');
            for(let i = 0; i < window.length; i++) {
                window[i].remove();
            }

            // delete windowMask
            let windowMask = document.querySelector('body').getElementsByClassName('window-mask');
            for(let i = 0; i < windowMask.length; i++) {
                windowMask[i].remove();
            }
        }
    }
}

export default new GlobalUtil();