export default{
    id : null,
    rate_status : null,
    client_id : null,
    transporter_id : null,
    origin_id : null,
    destination_id : null,
    type_id : null,
    status : null,
    currency : null,
    rate_type : null,
    vehicle_rate : null,
    unloading_cost : null,
    remark : null,
    start_shipment : null,
    start_drop_charge_in_city : null,
    rate_drop_charge_in_city : null,
    start_drop_charge_out_city : null,
    rate_drop_charge_out_city : null,
    start_diff_area_in_city : null,
    rate_diff_area_in_city : null,
    start_diff_area_out_city : null,
    rate_diff_area_out_city : null,
    area_in : null,
    area_out : null,
}