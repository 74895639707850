<template>
    <div>
        <Dialog ref="dlg" :iconCls="icon" bodyCls="f-column" :title="title" :draggable="true" :modal="true" closed :dialogStyle="{width:'750px', height:'600px'}">
            <div class="f-full" style="overflow:auto">
                <Panel>
                    <MenuButton text="Process" :plain="true">
                        <Menu @itemClick="onClickMenu($event)">
                            <MenuItem value="onViewTruckingOrder" text="Trucking Order" :disabled="menuAction.truckingOrder"></MenuItem>
                            <MenuItem value="onConfirm" text="Confirm Manifest" :disabled="menuAction.confirm"></MenuItem>
                            <MenuItem value="onUnConfirm" text="UnConfirm Manifest" :disabled="menuAction.unConfirm"></MenuItem> 
                            <MenuItem value="onManifestRate" text="Modify Rate" :disabled="menuAction.manifestRate"></MenuItem>
                            <MenuSep></MenuSep>
                            <MenuItem value="onPrint" text="Print Manifest" :iconCls="iconUtil.iconPrint" :disabled="menuAction.printManifest"></MenuItem>
                        </Menu>
                    </MenuButton>
                    <MenuButton text="Maps" :plain="true">
                        <Menu @itemClick="onClickMenu($event)" >
                            <MenuItem value="onViewTracking"  text="View On Vehicle Tracking"></MenuItem>
                        </Menu>
                    </MenuButton>
                    <div v-if="disableManifestConfirm" class="manifest-confirm">
                        [ CONFIRMED ]
                    </div>
                </Panel>
                <Form ref="form" :model="model" :rules="rules" @validate="errors=$event">
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Manifest</b></legend>
                        <table width="100%" cellspacing="1" cellpadding="5" border="0">
                            <tbody>
                                <tr>
                                    <td>Manifest ID</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.manifest_number" :disabled="disableData"></TextBox>
                                    </td>
                                    <td>Start</td>
                                    <td>
                                        <NumberBox inputId="start" name="start" class="f-field-text" v-model="model.start" :spinners="false">
                                            <Addon>
                                                <span class="text-inner-box">KM</span>
                                            </Addon>
                                        </NumberBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Schedule Date</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.schedule_date_desc" :disabled="disableData"></TextBox>
                                    </td>
                                    <td>Finish</td>
                                    <td>
                                        <NumberBox inputId="finish" name="finish" class="f-field-text" v-model="model.finish" :spinners="false">
                                            <Addon>
                                                <span class="text-inner-box">KM</span>
                                            </Addon>
                                        </NumberBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Trip</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.trip_no" :disabled="disableData"></TextBox>
                                    </td>
                                    <td>Mileage</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.mileage" :disabled="disableData" align="right">
                                            <Addon>
                                                <span class="text-inner-box">KM</span>
                                            </Addon>
                                        </TextBox>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Trucking Order ID</td>
                                    <td>
                                        <TextBox class="f-field-text" v-model="modelNotMapping.trucking_order_number" :disabled="disableData"></TextBox>
                                    </td>
                                    <td>Mode <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="transportModes" inputId="mode" name="mode" class="f-field-text" v-model="model.mode"></ComboBox>
                                        <div class="error">{{textError.mode}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Order Case <font color="red">*</font></td>
                                    <td>
                                        <ComboBox  :data="orderCases" inputId="order_case" name="order_case" class="f-field-text" v-model="model.order_case"></ComboBox>
                                        <div class="error">{{textError.order_case}}</div>
                                    </td>
                                    <td>Approved By</td>
                                    <td>
                                        <TextBox inputId="approved_by" name="approved_by" class="f-field-text" v-model="model.approved_by"></TextBox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </fieldset>
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Vehicle</b></legend>
                        <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                            <LinkButton :plain="true" :iconCls="iconUtil.iconEdit" @click="onEditVehicle()">{{ btnText.edit }}</LinkButton>
                        </div>
                        <DataGrid
                                ref="dataGridVehicle"
                                :columnResizing="true"
                                :border="false"                                    
                                :data="dataGridVehicle.rows"
                                style="width:676px;height:65px"
                                @rowDblClick="onRowVehicleDblClick()"
                                :selectionMode="dataGridVehicle.selectionMode"
                                >
                            <GridColumnGroup :frozen="true" align="left" width="370">
                                <GridHeaderRow>
                                    <GridColumn field="vehicle_desc" title="Vehicle Id" :frozen="true" width="90"></GridColumn>
                                    <GridColumn field="driver_desc" title="Driver" :frozen="true" width="140"></GridColumn>
                                    <GridColumn field="co_driver_desc" title="Co Driver" :frozen="true" width="140"></GridColumn>
                                </GridHeaderRow>
                            </GridColumnGroup>
                            <GridColumnGroup>
                                <GridHeaderRow>
                                    <GridColumn field="transporter_desc" title="Transporter Id" width="130"></GridColumn>
                                    <GridColumn field="status_desc" title="Status" width="100"></GridColumn>
                                    <GridColumn field="type_desc" title="Type" width="100"></GridColumn>
                                    <GridColumn field="max_volume" title="Max Volume" width="100"></GridColumn>
                                    <GridColumn field="max_weight" title="Max Weight" width="100"></GridColumn>
                                </GridHeaderRow>
                            </GridColumnGroup>                            
                        </DataGrid>
                    </fieldset>
                    <fieldset class="c-fieldset">
                        <legend class="c-legend"><b>Details</b></legend>
                        <Tabs style="height:276px" @tabSelect="onTabSelected($event)">
                            <TabPanel :title="'Loading Summary'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>Capacity</td>
                                            <td>
                                                <TextBox inputId="max_volume" name="max_volume" class="f-field-text" v-model="modelNotMapping.max_volume" :disabled="disableData">
                                                    <Addon>
                                                        <span class="text-inner-box">M3</span>
                                                    </Addon>
                                                </TextBox>
                                            </td>
                                            <td>Capacity</td>
                                            <td>
                                                <TextBox inputId="max_weight" name="max_weight" class="f-field-text" v-model="modelNotMapping.max_weight" :disabled="disableData">
                                                    <Addon>
                                                        <span class="text-inner-box">KG</span>
                                                    </Addon>
                                                </TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Load</td>
                                            <td>
                                                <NumberBox inputId="load_m3" name="load_m3" class="f-field-text" v-model="model.load_m3" :spinners="false" @valueChange="onChangeLoad()">
                                                    <Addon>
                                                        <span class="text-inner-box">M3</span>
                                                    </Addon>
                                                </NumberBox>
                                            </td>
                                            <td>Load</td>
                                            <td>
                                                <TextBox inputId="load_kg" name="load_kg" class="f-field-text" v-model="modelNotMapping.load_kg" :disabled="disableData">
                                                    <Addon>
                                                        <span class="text-inner-box">KG</span>
                                                    </Addon>
                                                </TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Utilization</td>
                                            <td>
                                                <TextBox inputId="utilization_m3" name="utilization_m3" class="f-field-text" v-model="modelNotMapping.utilization_m3" :disabled="disableData">
                                                    <Addon>
                                                        <span class="text-inner-box">%</span>
                                                    </Addon>
                                                </TextBox>
                                            </td>
                                            <td>Utilization</td>
                                            <td>
                                                <TextBox inputId="utilization_kg" name="utilization_kg" class="f-field-text" v-model="modelNotMapping.utilization_kg" :disabled="disableData">
                                                    <Addon>
                                                        <span class="text-inner-box">%</span>
                                                    </Addon>
                                                </TextBox>
                                            </td>
                                        </tr>
                                        <tr :hidden="disableClientMinWeight">
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>Min Weight</td>
                                            <td>
                                                <TextBox inputId="client_min_weight" name="client_min_weight" class="f-field-text" v-model="modelNotMapping.client_min_weight" :disabled="true">
                                                    <Addon>
                                                        <span class="text-inner-box">KG</span>
                                                    </Addon>
                                                </TextBox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                            <TabPanel :title="'Drop Sequence'">
                                <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                    <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                                    <span class="button-sep"></span>&nbsp;&nbsp;{{ search.text }} : 
                                    <SearchBox style="width:300px"
                                        :placeholder="search.placeholder" 
                                        v-model="dataGrid.search"
                                        @search="onSearch()">
                                        <Addon>
                                            <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearch();"></span>
                                        </Addon>
                                    </SearchBox>
                                </div>

                                <DataGrid
                                        ref="dataGrid"
                                        :columnResizing="true"
                                        :border="false"
                                        :loading="dataGrid.loading"                                    
                                        :data="dataGrid.rows"
                                        :pagination="true"
                                        :selectionMode="dataGrid.selectionMode"
                                        :total="dataGrid.total"
                                        :pageSize="dataGrid.pageSize"
                                        :pageNumber="dataGrid.pageNumber"
                                        :pageLayout="dataGrid.pageLayout"
                                        :footerData="dataGrid.footerData"
                                        :showFooter="true"
                                        @rowDblClick="onRowDblClick()"
                                        style="width:676px;height:203px"
                                        >
                                    <GridColumn field="customer_id" title="Point" :frozen="true" :sort="false"></GridColumn>
                                    <GridColumn field="customer_name" title="Name" :frozen="true"></GridColumn>
                                    <GridColumn field="customer_address" title="Address" width="100"></GridColumn>
                                    <GridColumn field="tm_state" title="Tm State" width="100"></GridColumn>
                                    <GridColumn field="ETA" title="ETA" width="100"></GridColumn>
                                    <GridColumn field="ATA" title="ATA" width="100"></GridColumn>
                                    <GridColumn field="EstDeparture" title="Est Departure" width="100"></GridColumn>
                                    <GridColumn field="ActualDeparture" title="Actual Departure" width="100"></GridColumn>
                                    <GridColumn field="LoadingTimes" title="Loading Times" width="100"></GridColumn>
                                    <GridColumn field="notes" title="Notes" width="100"></GridColumn>
                                </DataGrid>
                            </TabPanel>
                            <TabPanel :title="'Attachment'">
                                <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                    <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefreshFile()">{{ btnText.refresh }}</LinkButton>
                                    <LinkButton :plain="true" :iconCls="iconUtil.iconAdd" @click="onAddFile()">{{ btnText.add }}</LinkButton>
                                    <span class="button-sep"></span>&nbsp;&nbsp;{{ search.text }} : 
                                    <SearchBox style="width:300px"
                                        :placeholder="search.placeholder" 
                                        v-model="dataGridFile.search"
                                        @search="onSearchFile()">
                                        <Addon>
                                            <span v-if="dataGridFile.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGridFile.search=null;onSearchFile();"></span>
                                        </Addon>
                                    </SearchBox>
                                </div>

                                <DataGrid
                                        ref="dataGridFile"
                                        :columnResizing="true"
                                        :border="false"
                                        :loading="dataGridFile.loading"                                    
                                        :data="dataGridFile.rows"
                                        :pagination="true"
                                        :total="dataGridFile.total"
                                        :pageSize="dataGridFile.pageSize"
                                        :pageNumber="dataGridFile.pageNumber"
                                        :pageLayout="dataGridFile.pageLayout"
                                        :footerData="dataGridFile.footerData"
                                        :showFooter="true"
                                        style="width:676px;height:203px"
                                        >
                                    <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                                        <template slot="body" slot-scope="scope">
                                            {{scope.rowIndex + 1}}
                                        </template>
                                    </GridColumn>
                                    <GridColumn field="file_name" title="File Name" :sort="false"></GridColumn>
                                    <GridColumn field="act" title="Action" :sort="false">
                                        <template slot="body" slot-scope="scope">
                                            <LinkButton :plain="true" :iconCls="iconUtil.iconDownload" @click="onDownloadFile(scope.row)">{{ btnText.download }}</LinkButton>
                                            <LinkButton :plain="true" :iconCls="iconUtil.iconDelete" @click="onRemoveFile(scope.row)">{{ btnText.delete }}</LinkButton>
                                        </template>
                                    </GridColumn>
                                </DataGrid>
                            </TabPanel>
                            <TabPanel :title="'Log'">
                                <table width="100%" cellspacing="1" cellpadding="5" border="0">
                                    <tbody>
                                        <tr>
                                            <td>
                                                Created By
                                            </td>
                                            <td>
                                                <TextBox inputId="created_by" name="created_by" class="f-field-text" :value="modelNotMapping.created_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Created Date
                                            </td>
                                            <td>
                                                <TextBox inputId="created_date" name="created_date" class="f-field-text" :value="modelNotMapping.created_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Updated By
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_by" name="updated_by" class="f-field-text" :value="modelNotMapping.updated_by" :disabled="true"></TextBox>
                                            </td>
                                            <td>
                                                Updated Date
                                            </td>
                                            <td>
                                                <TextBox inputId="updated_date" name="updated_date" class="f-field-text" :value="modelNotMapping.updated_date" :disabled="true"></TextBox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </TabPanel>
                        </Tabs>
                    </fieldset>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" v-if="action.add || action.edit" @click="onSaveData()" :disabled="disableBtnSave" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlg.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <Dialog ref="dlgVehicle" :iconCls="icon" bodyCls="f-column" :title="titleVehicle" :draggable="true" :modal="true" closed :dialogStyle="{width:'380px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="formVehicle" :model="modelVehicle" :rules="rulesVehicle" @validate="errorsVehicle=$event">
                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                        <tbody>
                            <tr>
                                <td class="bg_subtitle">Vehicle ID <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="vehicles" inputId="vehicle_id" name="vehicle_id" @filterChange="optionVehicle($event)" class="f-field-text" v-model="modelVehicle.vehicle_id" :disabled="disableManifestConfirm">
                                        <template slot="item" slot-scope="scope">
                                            <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                            <span style="color:#888">{{scope.row.vehicleType}}</span><br/>
                                            <span style="color:#888">{{scope.row.transporter}} ({{scope.row.status}})</span><br/>
                                            <span style="color:#888">Max Weight : {{scope.row.max_weight}}  Max Volume : {{scope.row.max_volume}}</span><br/>
                                        </template>
                                    </ComboBox>
                                    <div class="error">{{textErrorVehicle.vehicle_id}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">Vehicle Status <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="vehicleStatuses" inputId="vehicle_status" name="vehicle_status" class="f-field-text" v-model="modelVehicle.vehicle_status" :disabled="disableManifestConfirm"></ComboBox>
                                    <div class="error">{{textErrorVehicle.vehicle_status}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">Vehicle Type <font color="red">*</font></td>
                                <td class="bg_grid2"> 
                                    <ComboBox  :data="vehicleTypes" inputId="vehicle_type" name="vehicle_type" @filterChange="optionVehicleType($event)" class="f-field-text" v-model="modelVehicle.vehicle_type" :disabled="disableManifestConfirm">
                                        <template slot="item" slot-scope="scope">
                                            <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                            <span style="color:#888">Weight : {{scope.row.weight_cap}}  Volume : {{scope.row.volume_cap}}</span><br/>
                                        </template>
                                    </ComboBox>
                                    <div class="error">{{textErrorVehicle.vehicle_type}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">
                                    Driver <font color="red">*</font>
                                </td>
                                <td class="bg_grid2"> 
                                    <ComboBox  :data="drivers" inputId="driver_id" name="driver_id" @filterChange="optionDriver($event)" class="f-field-text" v-model="modelVehicle.driver_id" :disabled="disableManifestSelfBilling">
                                        <template slot="item" slot-scope="scope">
                                            <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                            <span style="color:#888">{{scope.row.transporter}}</span><br/>
                                        </template>
                                    </ComboBox>
                                    <div class="error">{{textErrorVehicle.driver_id}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="bg_subtitle">Co Driver <font color="red">*</font></td>
                                <td class="bg_grid2">
                                    <ComboBox  :data="coDriver" inputId="co_driver_id" name="co_driver_id" @filterChange="optionCoDriver($event)" class="f-field-text" v-model="modelVehicle.co_driver_id" :disabled="disableManifestSelfBilling">
                                        <template slot="item" slot-scope="scope">
                                            <span style="font-weight:bold">{{scope.row.text}}</span><br/>
                                            <span style="color:#888">{{scope.row.transporter}}</span><br/>
                                        </template>
                                    </ComboBox>
                                    <div class="error">{{textErrorVehicle.co_driver_id}}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" @click="onSaveVehicle()" :disabled="disableBtnSaveVehicle" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlgVehicle.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <Dialog ref="dlgFile" :iconCls="iconUtil.iconAdd" bodyCls="f-column" :title="titleFile" :draggable="true" :modal="true" closed :dialogStyle="{width:'410px', height:'auto'}">
            <div class="f-full" style="overflow:auto">
                <Form ref="formFile">
                    <table width="100%" cellspacing="1" cellpadding="5" border="0">
                        <tbody>
                            <tr>
                                <td> 
                                    <FileButton style="width:100px" @select="onSelectedFile($event)">Choose File</FileButton> &nbsp;&nbsp;{{fileName}}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>(Total Max Upload file size 5MB, allowed file type .pdf)</b>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>
            </div>
            <div class="dialog-button">
                <LinkButton :iconCls="iconUtil.iconSave" @click="onSaveFile()" :disabled="disableBtnSaveFile" style="width:89px">{{ btnText.save }}</LinkButton>
                <LinkButton :iconCls="iconUtil.iconCancel" @click="$refs.dlgFile.close()" style="width:89px">{{ btnText.cancel }}</LinkButton>
            </div>
        </Dialog>

        <NotificationComponent ref="notification"></NotificationComponent>
        <FormTruckingOrderComponent ref="formTruckingOrder" actionData="trucking_order" @onSaveDataVehicle="onLoadVehicle"></FormTruckingOrderComponent>
        <FormTimeWindowComponent ref="formTimeWindow" @onSaveData="onSaveDropSequence()"></FormTimeWindowComponent>
        <FormManifestRateComponent ref="formManifestRate" :actionData="actionDefault" @onSaveData="onSaveManifestRate()"></FormManifestRateComponent>
        <PrintManifestComponent ref="printManifest" :manifestId="manifestId"></PrintManifestComponent>
    </div>
</template>
<script>
    import Manifest from '@/models/Manifest';
    import InformationUtil from '@/utils/InformationUtil';
    import AccessUtil from '@/utils/AccessUtil';
    import TransportService from '@/services/TransportService';
    import OptionService from '@/services/OptionService';
    import ModelUtil from '@/utils/ModelUtil';
    import IconUtil from '@/utils/IconUtil';
    import GlobalUtil from '@/utils/GlobalUtil';

    import NotificationComponent from '../NotificationComponent.vue';
    import FormTruckingOrderComponent from './FormTruckingOrderComponent.vue';
    import FormTimeWindowComponent from './FormTimeWindowComponent.vue';
    import FormManifestRateComponent from './FormManifestRateComponent.vue';
    import PrintManifestComponent from '../report/PrintManifestComponent.vue';

    export default {
        name : "FormManifestComponent",
        components : {
            NotificationComponent,
            FormTruckingOrderComponent,
            FormTimeWindowComponent,
            FormManifestRateComponent,
            PrintManifestComponent
        },
        props : {
            actionData : {
                type : String,
                default : ''
            }
        },
        data(){
            return {
                title : InformationUtil.editText + " " + InformationUtil.manifest,
                titleMessage : InformationUtil.manifest,
                titleVehicle : InformationUtil.editText + " " + InformationUtil.vehicle,
                titleFile : InformationUtil.uploadFile,
                iconUtil : IconUtil,
                icon : IconUtil.iconEdit,

                actionDefault : "",

                model : Manifest,
                action : {
                    view : false,
                    add : false,
                    edit : false,
                    delete : false
                },
                menuAction :{
                    unConfirm : true,
                    confirm : true,
                    manifestRate : true,
                    printManifest : false,
                    truckingOrder : false,                    
                },
                btnText :{
                    refresh : InformationUtil.refreshText,
                    add : InformationUtil.addText,
                    edit : InformationUtil.editText,
                    delete : InformationUtil.removeText,
                    save : InformationUtil.saveText,
                    cancel : InformationUtil.cancelText,
                    search : InformationUtil.searchText,
                    download : InformationUtil.downloadText,
                },
                search : {
                    text : InformationUtil.searching,
                    placeholder : InformationUtil.searchHolder
                },
                modelNotMapping : {
                    created_by : "",
                    updated_by : "",
                    created_date : "",
                    updated_date : "",
                    manifest_number : 0,
                    manifest_status : 0,
                    schedule_date_desc : null,
                    trip_no : null,
                    mileage : null,
                    trucking_order_number : null,
                    max_volume : null,
                    max_weight : null,
                    load_kg : null,
                    utilization_m3 : null,
                    utilization_kg : null,
                    invsal_hash_id : null,
                    invpch_hash_id : null,
                    client_min_weight : 0,
                    client_rate_type : 1,
                },
                
                disableBtnSaveVehicle : false,
                modelVehicle : {
                    id : null,
                    vehicle_id : null,
                    driver_id : null,
                    co_driver_id : null,
                    vehicle_type: null,
                    vehicle_status: null
                },
                disableData : true,
                disableBtnSave : false,
                disableManifestConfirm : false,
                disableManifestSelfBilling : false,
                disableClientMinWeight : true,

                dataGrid : {
                    loading: false,
                    rows : [],
                    total: 0,
                    start: 0,
                    pageNumber: 1,
                    pageSize: 5,
                    selectionMode : 'single',
                    pageLayout: ['first','prev','next','last','info'],

                    // filter
                    search : "",
                    sortField : "",
                    sortOrder : ""
                },

                dataGridVehicle : {
                    loading: false,
                    rows : [],
                    total: 0,
                    start: 0,
                    pageNumber: 1,
                    pageSize: 5,
                    selectionMode : 'single',

                    // filter
                    search : "",
                    sortField : "",
                    sortOrder : "",
                    id : null
                },

                dataGridFile : {
                    loading: false,
                    rows : [],
                    total: 0,
                    start: 0,
                    pageNumber: 1,
                    pageSize: 5,
                    selectionMode : 'single',
                    pageLayout: ['first','prev','next','last','info'],

                    // filter
                    search : "",
                    sortField : "",
                    sortOrder : ""
                },

                // validation
                rules: {
                    mode: 'required',
                    order_case: 'required'
                },
                errors: {},
                textError :{
                    mode : "",
                    order_case : ""
                },

                rulesVehicle: {
                    vehicle_id: 'required',
                    driver_id: 'required',
                    co_driver_id: 'required',
                    vehicle_type: 'required',
                    vehicle_status: 'required'
                },
                errorsVehicle: {},
                textErrorVehicle :{
                    vehicle_id: '',
                    driver_id: '',
                    co_driver_id: '',
                    vehicle_type: '',
                    vehicle_status: ''
                },

                // print 
                manifestId : 0,

                //combo box
                transportModes: [
                    { value :1, text :"LAND"},
                    { value :2, text :"AIR"},
                    { value :3, text :"SEA"},
                    { value :4, text :"RAILWAY"},
                ],
                orderCases: [
                    { value :0, text :"Regular"},
                    { value :1, text :"Urgent"},
                ],
                drivers : [],
                coDriver : [],
                vehicles : [],
                vehicleStatuses : [
                    {text: 'ON CALL', value: '1'},
                    {text: 'DEDICATED', value: '2'}
                ],
                vehicleTypes : [],

                // file data
                disableBtnSaveFile : false,
                file : '',
                fileName : '',
            }
            
        },
        methods : {
            onTabSelected(event){
                if(event.title == "Drop Sequence"){
                    this.onLoad();
                }
            },
            onClickMenu(row){
                if(row == "onViewTruckingOrder"){
                    this.$refs.formTruckingOrder.onPageShow(this.modelNotMapping.trucking_order_number);
                }
                
                if(row == "onPrint"){
                    this.onPrintManifest();
                }
                
                if(row == "onManifestRate"){
                    this.onManifestRate();
                }
                
                if(row == "onConfirm"){
                    this.onConfirm();
                }
                
                if(row == "onUnConfirm"){
                    this.onUnConfirm();
                }

                if(row == "onViewTracking"){
                    // open link 
                    window.open("https://tracking.tmsuksema.id?id_manifest="+this.model.id, '_blank');
                }
            },
            getError(name) {
                return this.errors[name] && this.errors[name].length ? this.errors[name][0] : null;
            },
            getErrorVehicle(name) {
                return this.errorsVehicle[name] && this.errorsVehicle[name].length ? this.errorsVehicle[name][0] : null;
            },
            onPageShow(id){
                this.actionDefault = this.$props.actionData;
                this.action = AccessUtil.getAccessAction(this.$props.actionData);
                if(this.action.view && id != null && id != undefined){
                    TransportService.get('/manifest/' + id)
                        .then((response) => {
                            let dataResponse = response;
                            if(dataResponse.data !== "" && dataResponse.data !== null){

                                this.model = ModelUtil.resetModel(this.model);
                                this.model = ModelUtil.mapModel(this.model, dataResponse.data);

                                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                                this.modelNotMapping = ModelUtil.mapModel(this.modelNotMapping, dataResponse.data);

                                this.model.start = parseInt(this.model.start);
                                this.model.finish = parseInt(this.model.finish);
                                this.model.load_m3 = parseInt(this.model.load_m3);
                                this.model.client_min_weight = parseInt(this.model.client_min_weight);
                                this.modelNotMapping.manifest_number = String(this.modelNotMapping.manifest_number);

                                this.modelNotMapping.utilization_kg = String((this.modelNotMapping.load_kg / this.modelNotMapping.max_weight * 100).toFixed(2));
                                if(this.modelNotMapping.utilization_kg == "NaN"){
                                    this.modelNotMapping.utilization_kg = "0.00";
                                }

                                this.modelNotMapping.utilization_m3 = String((this.model.load_m3 / this.modelNotMapping.max_volume * 100).toFixed(2));
                                if(this.modelNotMapping.utilization_m3 == "NaN"){
                                    this.modelNotMapping.utilization_m3 = "0.00";
                                }

                                this.menuAction.confirm = (dataResponse.data.manifest_status == 0) ? false : true;
                                this.menuAction.unConfirm = (dataResponse.data.manifest_status == 1) ? false : true;
                                this.menuAction.manifestRate = (dataResponse.data.manifest_status == 1) ? false : true;

                                this.disableManifestConfirm = this.modelNotMapping.manifest_status > 0 ? true : false;
                                this.disableManifestSelfBilling = this.modelNotMapping.invpch_hash_id != null && this.modelNotMapping.invpch_hash_id != '' ? true : false;
                                this.disableClientMinWeight = this.modelNotMapping.client_rate_type == 2 ? false : true;

                                this.$refs.dlg.open();

                                this.onLoadVehicle();
                                this.onLoad();
                                this.onLoadFile();
                                this.manifestId = parseInt(this.modelNotMapping.manifest_number)
                            }
                        });
                }
            },
            onPageClose(){
                this.model = ModelUtil.resetModel(this.model);
                this.modelNotMapping = ModelUtil.resetModel(this.modelNotMapping);
                this.errors = {};
                this.textError = {};
                this.$refs.dlg.close();
            },  
            onSaveData(){
                this.$refs.form.validate((valid) => {
                    if(!valid){
                        // save data
                        this.disableBtnSave = true;
                        TransportService.updateData("/manifest", this.model.id, this.model)
                            .then((res) => {
                                this.disableBtnSave = false;
                                let dataResponse = res;
                                if(dataResponse.code == 200){
                                    this.$refs.dlg.close();                                    
                                    // set event after save
                                    this.$emit('onSaveData', this.model);
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: dataResponse.data.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });

                    } else {
                        for(let key in valid){
                            this.textError[key] = this.getError(key);
                        }
                    }
                });
            },
            onConfirm(){
                TransportService.post("/manifest/confirm", this.model)
                    .then((res) => {
                        if(res.code == 200){
                            this.$refs.dlg.close();                                    
                            // set event after save
                            this.$emit('onConfirmManifest', this.model);
                        } else {
                            this.$messager.alert({
                                title: "Error",
                                icon: "error",
                                msg: res.message
                            });
                        }
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },

            onUnConfirm(){
                TransportService.post("/manifest/unConfirm", this.model)
                    .then((res) => {
                        if(res.code == 200){
                            this.$refs.dlg.close();                                    
                            // set event after save
                            this.$emit('onSaveData', this.model);
                        } else {
                            this.$messager.alert({
                                title: "Error",
                                icon: "error",
                                msg: res.message
                            });
                        }
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },

            // data grid vehicle
            onEditVehicle(){
                this.disableBtnSaveVehicle = this.modelNotMapping.invpch_hash_id != null && this.modelNotMapping.invpch_hash_id != '' ? true : false;
                this.textErrorVehicle = ModelUtil.resetModel(this.textErrorVehicle);
                if(this.$refs.dataGridVehicle.selectedRows.length > 0){
                    this.onCallAllOption();
                    this.vehicles = [{value: this.$refs.dataGridVehicle.selectedRows[0].vehicle_id, text: this.$refs.dataGridVehicle.selectedRows[0].vehicle_desc}]
                    this.drivers = [{value: this.$refs.dataGridVehicle.selectedRows[0].driver_id, text: this.$refs.dataGridVehicle.selectedRows[0].driver_desc}]
                    this.coDriver = [{value: this.$refs.dataGridVehicle.selectedRows[0].co_driver_id, text: this.$refs.dataGridVehicle.selectedRows[0].co_driver_desc}]
                    this.modelVehicle.vehicle_status = this.$refs.dataGridVehicle.selectedRows[0].vehicle_status;
                    this.vehicleTypes = [{value: this.$refs.dataGridVehicle.selectedRows[0].vehicle_type, text: this.$refs.dataGridVehicle.selectedRows[0].vehicle_type_desc, volume_cap: this.$refs.dataGridVehicle.selectedRows[0].max_volume, weight_cap: this.$refs.dataGridVehicle.selectedRows[0].max_weight}]

                    this.$refs.dlgVehicle.open();
                }else{
                    this.$messager.alert({
                                        title: "Info",
                                        icon: "info",
                                        msg: InformationUtil.selectRow
                                    });
                }
            },
            onRowVehicleDblClick(){
                this.onEditVehicle();
            },
            onSaveVehicle(){
                this.$refs.formVehicle.validate((valid) => {
                    if(!valid){
                        // save data
                        this.disableBtnSaveVehicle = true;
                        this.modelVehicle.id = this.$refs.dataGridVehicle.selectedRows[0].id;
                        // action update data
                        TransportService.post("/manifest/updateVehicle", this.modelVehicle)
                            .then((res) => {
                                this.disableBtnSave = false;
                                if(res.code == 200){
                                    this.textErrorVehicle = ModelUtil.resetModel(this.textErrorVehicle);
                                    this.$refs.notification.success(this.titleMessage + " " + InformationUtil.update);
                                    this.onLoadVehicle();
                                    this.model.vehicle_id = this.modelVehicle.vehicle_id;
                                    this.$refs.dlgVehicle.close();
                                    this.$emit('onSaveDataVehicle', this.modelVehicle);
                                } else {
                                    this.$messager.alert({
                                        title: "Error",
                                        icon: "error",
                                        msg: res.message
                                    });
                                }
                            }).catch((error) => {
                                this.disableBtnSave = false;
                                this.$refs.notification.error(error);
                            });

                    } else {
                        for(let key in valid){
                            this.textErrorVehicle[key] = this.getErrorVehicle(key);
                        }
                    }
                });
            },
            onLoadVehicle(){
                this.dataGridVehicle.id = this.model.id;
                return TransportService.post('/manifest/showVehicle', this.dataGridVehicle)
                    .then((response) => {
                        this.dataGridVehicle.loading = false;
                        if(response.data !== "" && response.data !== null){
                            if(response.code == 200){
                                let data = response.data
                                this.dataGridVehicle.total = data.total;
                                this.dataGridVehicle.rows = data.rows;

                                if(data.rows.length > 0){
                                    this.modelVehicle = ModelUtil.resetModel(this.modelVehicle);
                                    this.modelVehicle = ModelUtil.mapModel(this.modelVehicle, data.rows[0]);
                                }

                            }else{
                                this.$messager.alert({
                                    title: "Error",
                                    icon: "error",
                                    msg: response.data.message
                                });
                            }
                        }
                    });
            },

            // data grid drop Sequence
            async onSaveDropSequence(){
                await this.onLoad();
                this.$emit('onSaveTimeWindow', this.model);
            },
            async onTabDropSequence(){
                await this.onLoad();
            },
            async onRefresh(){
                this.dataGrid.pageNumber = 1;
                this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
                await this.onLoad();
                this.$refs.notification.success(InformationUtil.refresh);
            },
            async onSearch(){
                await this.onLoad();
            },
            onRowDblClick(){
                if(this.action.edit){
                    let data = this.$refs.dataGrid.selectedRows[0];
                    let param = {
                        manifestId : this.model.id,
                        pointId : data.point_id,
                        tmState : data.tm_state,
                        trafficMonitoringId : data.id
                    }
                    this.$refs.formTimeWindow.onPageShow(param);
                }
            },
            onLoad(){
                this.dataGrid.loading = true;
                let param = {
                    manifestId : this.model.id
                }
                return TransportService.getData('/timeWindow', this.dataGrid, param)
                    .then((response) => {
                        this.dataGrid.loading = false;
                        if(response.data !== "" && response.data !== null){
                            if(response.code == 200){
                                let data = response.data;
                                this.dataGrid.total = data.total;
                                this.dataGrid.rows = data.rows;
                            }else{
                                this.$messager.alert({
                                    title: "Error",
                                    icon: "error",
                                    msg: response.data.message
                                });
                            }
                        }
                    });
            },

            onChangeLoad(){
                this.modelNotMapping.utilization_m3 = String((this.model.load_m3 / this.modelNotMapping.max_volume * 100).toFixed(2));
                if(this.modelNotMapping.utilization_m3 == "NaN"){
                    this.modelNotMapping.utilization_m3 = "0.00";
                }
            },

            // manifest file
            onAddFile(){
                this.$refs.dlgFile.open();
                this.file = null;
                this.fileName = "";
            },
            onSelectedFile(event){
                this.file = event[0];
                this.fileName = this.file.name;
            },
            onSaveFile(){
                if(this.file != null && this.file != ''){
                    let formData = new FormData();
                    formData.append('file', this.file);
                    formData.append('manifest_id', this.model.id);
                    TransportService.uploadFile("/manifestFile", formData)
                        .then((res) => {
                            if(res.code == 200){
                                this.$refs.notification.success(this.titleMessage + " " + InformationUtil.save);
                                this.onLoadFile();
                                this.$refs.dlgFile.close();
                            } else {
                                this.$messager.alert({
                                    title: "Error",
                                    icon: "error",
                                    msg: res.message
                                });
                            }
                        }).catch((error) => {
                            this.$refs.notification.error(error);
                        });
                } else {
                    this.$messager.alert({
                        title: "Info",
                        icon: "info",
                        msg: InformationUtil.infoSelectFile
                    });
                }
            },
            onDownloadFile(row){
                if(row.id != null && row.id != ""){
                    let param = {
                        id : row.id
                    }
                    TransportService.downloadFile("/manifestFile/download", param)
                        .then((res) => {
                            if(res != null && res.type === 'application/pdf'){
                                let blob = new Blob([res], { type: 'application/pdf' })
                                let objectUrl = window.URL.createObjectURL(blob)
                                window.open(objectUrl)
                                window.URL.revokeObjectURL(blob);
                                this.$refs.notification.success(this.titleMessage + " " + InformationUtil.download);
                            } else {
                                let message = new Blob([res], { type: 'application/json' })
                                    message.text()
                                        .then(value => {
                                            let valMsg = JSON.parse(value);
                                            this.$messager.alert({
                                                title: "Error",
                                                icon: "error",
                                                msg: valMsg.message
                                            });
                                        })
                                        .catch(error => {
                                            console.log("Something went wrong" + error);
                                        });
                            }
                        }).catch((error) => {
                            this.$refs.notification.error(error);
                        });
                } else {
                    this.$messager.alert({
                        title: "Info",
                        icon: "info",
                        msg: InformationUtil.infoSelectFile
                    });
                }
            },
            onRemoveFile(row){
                if(row.id != null && row.id != ""){
                    this.$messager.confirm({
                        title: 'Confirm',
                        msg: InformationUtil.confirmDelete,
                        result: (r) => {
                            if (r){
                                TransportService.deleteData("/manifestFile", row.id)
                                    .then((res) => {
                                        if(res.code == 200){
                                            this.$refs.notification.success(this.titleMessage + " " + InformationUtil.delete);
                                            this.onLoadFile();
                                        } else {
                                            this.$messager.alert({
                                                title: "Error",
                                                icon: "error",
                                                msg: res.message
                                            });
                                        }
                                    }).catch((error) => {
                                        this.$refs.notification.error(error);
                                    });
                            }
                        }
                    })
                } else {
                    this.$messager.alert({
                        title: "Info",
                        icon: "info",
                        msg: InformationUtil.infoSelectFile
                    });
                }
            },
            async onRefreshFile(){
                await this.onLoadFile();
            },
            async onSearchFile(){
                await this.onLoadFile();
            },
            onLoadFile(){
                this.dataGridFile.loading = true;
                let param = {
                    manifestId : this.model.id
                }
                return TransportService.getData('/manifestFile', this.dataGridFile, param)
                    .then((response) => {
                        this.dataGridFile.loading = false;
                        if(response.data !== "" && response.data !== null){
                            if(response.code == 200){
                                let data = response.data;
                                this.dataGridFile.total = data.total;
                                this.dataGridFile.rows = data.rows;
                            }else{
                                this.$messager.alert({
                                    title: "Error",
                                    icon: "error",
                                    msg: response.data.message
                                });
                            }
                        }
                    });
            },

            // data rate
            onSaveManifestRate(data){
                this.$emit('onSaveManifestRate', data);
            },
            onManifestRate(){
                this.$refs.formManifestRate.onPageShow(this.model.id);
            },

            // print manifest
            onPrintManifest(){
                this.manifestId = this.model.id;
                this.$refs.printManifest.printManifest();
            },

            async optionDriver(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/driver", search)
                    .then((response) => {
                        this.drivers = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            async optionCoDriver(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/driver", search)
                    .then((response) => {
                        this.coDriver = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            async optionVehicle(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/vehicle", search)
                    .then((response) => {
                        this.vehicles = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            async optionVehicleType(event){
                let search = event == null ? "" : event.filterValue;
                return await OptionService.getOption("/vehicleType", search)
                    .then((response) => {
                        this.vehicleTypes = response.data;
                    }).catch((error) => {
                        this.$refs.notification.error(error);
                    });
            },
            onCallAllOption(){
                this.optionDriver();
                this.optionCoDriver();
                this.optionVehicle();
                this.optionVehicleType();
            },
        },
        created(){
            GlobalUtil.removeWindow();
        }
    }
</script>