<template>
    <div>
        <notifications group="right-bottom" position="bottom right" >
            <template slot="body">
                <Panel :iconCls="iconCls" :title="title" :bodyStyle="{padding:'10px'}" style="height:100px; margin: 0px 5px 35px 0px;z-index : 9999 !important;">
                    <div v-html="text"></div>
                </Panel>
            </template>
        </notifications>
        <notifications group="right-bottom-error" position="bottom right" >
            <template slot="body">
                <Panel :iconCls="iconClsError" :title="titleError" :bodyStyle="{padding:'10px'}" style="height:100px; margin: 0px 5px 35px 0px;z-index : 9999 !important;">
                    <div v-html="textError"></div>
                </Panel>
            </template>
        </notifications>
        <notifications group="center-top" position="top center">
            <template slot="body">
                <Panel :iconCls="iconClsCenter" :title="titleCenter" :bodyStyle="{padding:'10px'}" style="height:100px; margin: 10px 0px 0px 0px;z-index : 9999 !important;">
                    <div v-html="textCenter"></div>
                </Panel>
            </template>
        </notifications>
    </div>
  </template>
   
  <script>
  import InformationUtil from '@/utils/InformationUtil';
  import IconUtil from '@/utils/IconUtil';
  export default {
    name: "NotificationComponent",
    data() {
      return {
        informationUtil : InformationUtil,
        title: InformationUtil.successText,
        text: InformationUtil.dataSaveSuccess,
        iconCls: IconUtil.iconOk,

        titleError: InformationUtil.errorText,
        textError: '',
        iconClsError: IconUtil.iconNo,

        // center top
        titleCenter: InformationUtil.infoText,
        textCenter: InformationUtil.selectRow,
        iconClsCenter: IconUtil.iconTip,
      };
    },
    methods: {
        success(message) {
            this.iconCls = IconUtil.iconOk;
            this.text = message == null ? InformationUtil.successText : message;
            this.$notify({
                group: 'right-bottom',
                title: this.title,
                text: message == null ? InformationUtil.successText : message,
                type: 'success',
                duration: 2000
            });
        },
        error(message) {
            this.iconClsError = IconUtil.iconNo;
            this.titleError = this.informationUtil.errorText;
            this.textError = message == null ? this.informationUtil.formErrorTitle : message;
            this.$notify({
                group: 'right-bottom-error',
                title: this.informationUtil.errorText,
                text: message == null ? this.informationUtil.formErrorTitle : message,
                type: 'error',
                duration: 2000
            });
        },
        warning(message) {
            this.iconCls = IconUtil.iconWarning;
            this.title = InformationUtil.warningText;
            this.text = message == null ? InformationUtil.formWarningTitle : message;
            this.$notify({
                group: 'right-bottom',
                title: InformationUtil.warningText,
                text: message == null ? InformationUtil.formWarningTitle : message,
                type: 'warn',
                duration: 2000
            });
        },
        info(message) {
            this.iconCls = IconUtil.iconInfo;
            this.title = InformationUtil.infoText;
            this.text = message == null ? InformationUtil.formInfoTitle : message;
            this.$notify({
                group: 'right-bottom',
                title: InformationUtil.infoText,
                text: message == null ? InformationUtil.formInfoTitle : message,
                type: 'info',
                duration: 2000
            });
        },

        centerTop(message) {
            this.iconClsCenter = IconUtil.iconTip;
            this.titleCenter = InformationUtil.infoText;
            this.textCenter = message == null ? InformationUtil.formInfoTitle : message;
            this.$notify({
                group: 'center-top',
                title: InformationUtil.infoText,
                text: message == null ? InformationUtil.formInfoTitle : message,
                type: 'info',
                duration: 2000
            });
        }
    }
  };
  </script>