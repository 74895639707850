<template>
    <div>
        <Panel :title="titleView" v-if="useDashboard"  style="margin:0px 1px; min-height: 480px; ">
            <div class="datagrid-toolbar">
                <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefresh()">{{ btnText.refresh }}</LinkButton>
                <span class="button-sep"></span>
                &nbsp;&nbsp;{{ filter.fromDate.text }}&nbsp;&nbsp;<DateBox v-model="filter.fromDate.value" format="dd/MM/yyyy" style="width: 175px"></DateBox>
                &nbsp;&nbsp;{{ filter.toDate.text }}  &nbsp;&nbsp;<DateBox v-model="filter.toDate.value" format="dd/MM/yyyy" style="width: 175px"></DateBox>
            </div>
            <Layout style="margin : 10px 0px;">
                <LayoutPanel>
                    <b-container fluid>
                        <b-row class="mt-2">
                            <b-col md="3" sm="12" >
                                <b-card class="card-congratulation-medal">
                                    <h5>Welcome {{ full_name }}! 🎉</h5>
                                    <b-card-text class="font-small-3">
                                        Have a good day!
                                    </b-card-text>
                                    <h3 class="mb-75 mt-4">
                                        <b-link></b-link>
                                    </h3>
                                    <b-img
                                        :src="require('@/assets/images/dashboard-user.png')"
                                        class="congratulation-medal"
                                    />
                                </b-card>
                            </b-col>
                            <b-col md="9" sm="12">
                                <b-card no-body class="card-statistics" >
                                    <b-card-header>
                                        <b-card-title>Summary Stats</b-card-title>
                                    </b-card-header>
                                    <b-card-body class="statistics-body">
                                        <b-row>
                                            <b-col md="3" sm="6" class="mb-2 mb-md-0 mb-2 mb-xl-0" >
                                                <b-media no-body>
                                                    <b-media-aside class="mr-2">
                                                        <b-avatar size="48" variant="light-primary" >
                                                            <feather-icon size="24" icon="TruckIcon"/>
                                                        </b-avatar>
                                                    </b-media-aside>
                                                    <b-media-body class="my-auto">
                                                        <h5 class="font-weight-bolder mb-0">
                                                            {{ dataDocument.totalShipment }}
                                                        </h5>
                                                        <b-card-text class="font-small-4 mb-0">
                                                            Total Shipment
                                                        </b-card-text>
                                                    </b-media-body>
                                                </b-media>
                                            </b-col>
                                            <b-col md="3" sm="6" class="mb-2 mb-md-0 mb-2 mb-sm-0" >
                                                <b-media no-body>
                                                    <b-media-aside class="mr-2">
                                                        <b-avatar size="48" variant="light-info" >
                                                            <feather-icon size="24" icon="CalendarIcon"/>
                                                        </b-avatar>
                                                    </b-media-aside>
                                                    <b-media-body class="my-auto">
                                                        <h5 class="font-weight-bolder mb-0">
                                                            {{ dataDocument.totalAllPod }}
                                                        </h5>
                                                        <b-card-text class="font-small-4 mb-0">
                                                            Total POD
                                                        </b-card-text>
                                                    </b-media-body>
                                                </b-media>
                                            </b-col>
                                            <b-col md="3" sm="6" class="mb-2 mb-md-0 mb-2 mb-sm-0" >
                                                <b-media no-body>
                                                    <b-media-aside class="mr-2">
                                                        <b-avatar size="48" variant="light-danger" >
                                                            <feather-icon size="24" icon="ShoppingCartIcon"/>
                                                        </b-avatar>
                                                    </b-media-aside>
                                                    <b-media-body class="my-auto">
                                                        <h5 class="font-weight-bolder mb-0">
                                                            {{ dataCost.totalCost }}
                                                        </h5>
                                                        <b-card-text class="font-small-4 mb-0">
                                                            Total Cost
                                                        </b-card-text>
                                                    </b-media-body>
                                                </b-media>
                                            </b-col>
                                            <b-col md="3" sm="6" class="mb-2 mb-md-0" >
                                                <b-media no-body>
                                                    <b-media-aside class="mr-2">
                                                        <b-avatar size="48" variant="light-success" >
                                                            <feather-icon size="24" icon="CreditCardIcon"/>
                                                        </b-avatar>
                                                    </b-media-aside>
                                                    <b-media-body class="my-auto">
                                                        <h5 class="font-weight-bolder mb-0">
                                                            {{ dataCost.totalRevenue }}
                                                        </h5>
                                                        <b-card-text class="font-small-4 mb-0">
                                                            Total Revenue
                                                        </b-card-text>
                                                    </b-media-body>
                                                </b-media>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="4">
                                <b-card no-body>
                                    <b-card-header class="pb-0">
                                        <b-card-title>POD Status</b-card-title>
                                    </b-card-header>

                                    <b-card-body>
                                        <b-row>
                                            <b-col md="4" class="d-flex flex-column flex-wrap  mt-2" >
                                                <div class="text-left mt-2">
                                                    <h3 class="font-weight-bolder mb-0">
                                                        <b>{{dataDocument.totalAllPod}}</b>
                                                    </h3>
                                                    <h6>Total POD</h6>
                                                </div>
                                                <div class="mt-2">
                                                    <b-media no-body class="p-1" >
                                                        <b-media-aside class="mr-1">
                                                            <b-avatar rounded variant="light-danger" size="44" >
                                                                <feather-icon icon="InfoIcon" size="44"/>
                                                            </b-avatar>
                                                        </b-media-aside>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ dataDocument.totalPendingPod }}
                                                            </h5>
                                                            <big>Pending POD</big>
                                                        </b-media-body>
                                                    </b-media>
                                                    <b-media no-body class="p-1">
                                                        <b-media-aside class="mr-1">
                                                            <b-avatar rounded variant="light-success" size="44">
                                                                <feather-icon icon="CheckSquareIcon" size="44"/>
                                                            </b-avatar>
                                                        </b-media-aside>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ dataDocument.totalPod }}
                                                            </h5>
                                                            <big>Finish POD</big>
                                                        </b-media-body>
                                                    </b-media>
                                                </div> 
                                            </b-col>

                                            <!-- chart -->
                                            <b-col md="8" class="d-flex justify-content-center">
                                                <vue-apex-charts
                                                    type="radialBar"
                                                    height="275"
                                                    :options="truckingData.chartOptions"
                                                    :series="truckingData.series"
                                                />
                                            </b-col>
                                            <!--/ chart -->
                                        </b-row>
                                    </b-card-body>
                                </b-card>
                            </b-col>

                            <b-col md="4">
                                <b-card no-body>
                                    <b-card-header class="pb-0">
                                        <b-card-title>Trucking Order Status</b-card-title>
                                    </b-card-header>

                                    <b-card-body>
                                        <b-row>
                                            <b-col md="4" class="d-flex flex-column flex-wrap  mt-2" >
                                                <div class="text-left mt-2">
                                                    <h3 class="font-weight-bolder mb-0">
                                                        <b>{{ truckingOrderStatus.totalData }}</b>
                                                    </h3>
                                                    <h6>Total Trucking</h6>
                                                </div>
                                                <div class="mt-2">
                                                    <b-media no-body class="p-1" >
                                                        <b-media-aside class="mr-1">
                                                            <b-avatar rounded variant="light-danger" size="44" >
                                                                <feather-icon icon="InfoIcon" size="44"/>
                                                            </b-avatar>
                                                        </b-media-aside>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ truckingOrderStatus.open }}
                                                            </h5>
                                                            <big>Open</big>
                                                        </b-media-body>
                                                    </b-media>
                                                    <b-media no-body class="p-1">
                                                        <b-media-aside class="mr-1">
                                                            <b-avatar rounded variant="light-success" size="44">
                                                                <feather-icon icon="CheckSquareIcon" size="44"/>
                                                            </b-avatar>
                                                        </b-media-aside>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ truckingOrderStatus.close }}
                                                            </h5>
                                                            <big>Close</big>
                                                        </b-media-body>
                                                    </b-media>
                                                </div> 
                                            </b-col>

                                            <!-- chart -->
                                            <b-col md="8" class="d-flex justify-content-center">
                                                <vue-apex-charts
                                                    type="radialBar"
                                                    height="275"
                                                    :options="truckingOrderStatus.chartOptions"
                                                    :series="truckingOrderStatus.series"
                                                />
                                            </b-col>
                                            <!--/ chart -->
                                        </b-row>
                                    </b-card-body>
                                </b-card>
                            </b-col>

                            <b-col md="4">
                                <b-card no-body>
                                    <b-card-header class="pb-0">
                                        <b-card-title>Delivery Status</b-card-title>
                                    </b-card-header>

                                    <b-card-body>
                                        <b-row>
                                            <b-col md="4" class="d-flex flex-column flex-wrap  mt-2" >
                                                <div class="text-left mt-2">
                                                    <h3 class="font-weight-bolder mb-0">
                                                        <b>{{truckingDeliveryStatus.totalData}}</b>
                                                    </h3>
                                                    <h6>Total Shipment</h6>
                                                </div>
                                                <div class="mt-2">
                                                    <b-media no-body class="p-1" >
                                                        <b-media-aside class="mr-1">
                                                            <b-avatar rounded variant="light-danger" size="44" >
                                                                <feather-icon icon="InfoIcon" size="44"/>
                                                            </b-avatar>
                                                        </b-media-aside>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ truckingDeliveryStatus.onGoing }}
                                                            </h5>
                                                            <big>On Going</big>
                                                        </b-media-body>
                                                    </b-media>
                                                    <b-media no-body class="p-1">
                                                        <b-media-aside class="mr-1">
                                                            <b-avatar rounded variant="light-success" size="44">
                                                                <feather-icon icon="CheckSquareIcon" size="44"/>
                                                            </b-avatar>
                                                        </b-media-aside>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ truckingDeliveryStatus.delivered }}
                                                            </h5>
                                                            <big>Delivered</big>
                                                        </b-media-body>
                                                    </b-media>
                                                </div> 
                                            </b-col>

                                            <!-- chart -->
                                            <b-col md="8" class="d-flex justify-content-center">
                                                <vue-apex-charts
                                                    type="radialBar"
                                                    height="275"
                                                    :options="truckingDeliveryStatus.chartOptions"
                                                    :series="truckingDeliveryStatus.series"
                                                />
                                            </b-col>
                                            <!--/ chart -->
                                        </b-row>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="9" sm="12">
                                <b-card no-body>
                                    <b-card-header>
                                        <div>
                                            <b-card-title>Shipment Activity by Type</b-card-title>
                                        </div>
                                    </b-card-header>
                                    <b-card-body>
                                        <b-row class="pb-50"> 
                                            <!-- text and button -->
                                            <b-col
                                                lg="2"
                                                order-lg="1"
                                                order="2"
                                                class="d-flex justify-content-center align-items-center flex-column"
                                            >
                                                <div class="text-center">
                                                    <h2 class="font-weight-bolder mb-25">
                                                        {{ shipmentByType.totalData }}
                                                    </h2>
                                                    <big>
                                                        Total Shipment
                                                    </big>
                                                </div>

                                            </b-col>

                                            <!-- dropdown and chart -->
                                            <b-col
                                                lg="10"
                                                cols="12"
                                                order-lg="2"
                                                order="1"
                                                class="d-flex justify-content-between flex-column text-right"
                                            >
                                                <vue-apex-charts
                                                    ref="shipmentByTypeChart"
                                                    type="bar"
                                                    height="260"
                                                    :options="shipmentByType.chartOptions"
                                                    :series="shipmentByType.series"
                                                />
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-card>                                                       
                            </b-col>
                            <b-col md="3" sm="12">
                                <b-card no-body class="card-browser-states">
                                    <b-card-header>
                                        <div>
                                            <b-card-title>Traffic Monitoring stats</b-card-title>
                                        </div>
                                    </b-card-header>
                                    <b-card-body>
                                        <div>
                                            <b-row>
                                                <b-col md="3">
                                                    <b-media no-body>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ dataTrafficMonitoring.pickup }}
                                                            </h5>
                                                            <small>Pickup Order</small>
                                                        </b-media-body>
                                                    </b-media>
                                                </b-col>
                                                <b-col md="7">
                                                    <div class="align-items-center">
                                                        <ProgressBar :value="dataTrafficMonitoring.pickupPercent" class="progress-pickup"></ProgressBar>
                                                    </div>
                                                </b-col>
                                                <b-col md="2" class="p-0 m-0">
                                                    <span class="font-weight-bold text-body-heading mr-1">{{dataTrafficMonitoring.pickupPercent}} %</span>
                                                </b-col>
                                            </b-row>
                                            <b-row class="mt-2">
                                                <b-col md="3">
                                                    <b-media no-body>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ dataTrafficMonitoring.loading }}
                                                            </h5>
                                                            <small>Loading</small>
                                                        </b-media-body>
                                                    </b-media>
                                                </b-col>
                                                <b-col md="7">
                                                    <div class="align-items-center">
                                                        <ProgressBar :value="dataTrafficMonitoring.loadingPercent" class="progress-loading"></ProgressBar>
                                                    </div>
                                                </b-col>
                                                <b-col md="2" class="p-0 m-0">
                                                    <span class="font-weight-bold text-body-heading mr-1">{{dataTrafficMonitoring.loadingPercent}} %</span>
                                                </b-col>
                                            </b-row>

                                            <b-row class="mt-2">
                                                <b-col md="3">
                                                    <b-media no-body>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ dataTrafficMonitoring.finishLoading }}
                                                            </h5>
                                                            <small>Finish Loading</small>
                                                        </b-media-body>
                                                    </b-media>
                                                </b-col>
                                                <b-col md="7">
                                                    <div class="align-items-center">
                                                        <ProgressBar :value="dataTrafficMonitoring.finishLoadingPercent" class="progress-finish-loading"></ProgressBar>
                                                    </div>
                                                </b-col>
                                                <b-col md="2" class="p-0 m-0">
                                                    <span class="font-weight-bold text-body-heading mr-1">{{dataTrafficMonitoring.finishLoadingPercent}} %</span>
                                                </b-col>
                                            </b-row>

                                            <b-row class="mt-2">
                                                <b-col md="3">
                                                    <b-media no-body>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ dataTrafficMonitoring.arrived }}
                                                            </h5>
                                                            <small>Arrived</small>
                                                        </b-media-body>
                                                    </b-media>
                                                </b-col>
                                                <b-col md="7">
                                                    <div class="align-items-center">
                                                        <ProgressBar :value="dataTrafficMonitoring.arrivedPercent" class="progress-arrived"></ProgressBar>
                                                    </div>
                                                </b-col>
                                                <b-col md="2" class="p-0 m-0">
                                                    <span class="font-weight-bold text-body-heading mr-1">{{dataTrafficMonitoring.arrivedPercent}} %</span>
                                                </b-col>
                                            </b-row>

                                            <b-row class="mt-2">
                                                <b-col md="3">
                                                    <b-media no-body>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ dataTrafficMonitoring.unloading }}
                                                            </h5>
                                                            <small>Unloading</small>
                                                        </b-media-body>
                                                    </b-media>
                                                </b-col>
                                                <b-col md="7">
                                                    <div class="align-items-center">
                                                        <ProgressBar :value="dataTrafficMonitoring.unloadingPercent" class="progress-loading"></ProgressBar>
                                                    </div>
                                                </b-col>
                                                <b-col md="2" class="p-0 m-0">
                                                    <span class="font-weight-bold text-body-heading mr-1">{{dataTrafficMonitoring.unloadingPercent}} %</span>
                                                </b-col>
                                            </b-row>

                                            <b-row class="mt-2">
                                                <b-col md="3">
                                                    <b-media no-body>
                                                        <b-media-body class="ml-2">
                                                            <h5 class="mb-0">
                                                                {{ dataTrafficMonitoring.finishUnloading }}
                                                            </h5>
                                                            <small>Finish Unloading</small>
                                                        </b-media-body>
                                                    </b-media>
                                                </b-col>
                                                <b-col md="7">
                                                    <div class="align-items-center">
                                                        <ProgressBar :value="dataTrafficMonitoring.finishUnloadingPercent" class="progress-finish-loading"></ProgressBar>
                                                    </div>
                                                </b-col>
                                                <b-col md="2" class="p-0 m-0">
                                                    <span class="font-weight-bold text-body-heading mr-1">{{dataTrafficMonitoring.finishUnloadingPercent}} %</span>
                                                </b-col>
                                            </b-row>

                                        </div>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                        </b-row>

                        
                        
                        <b-row>
                            <b-col md="4">
                                <b-card
                                    no-body
                                    class="card-transaction"
                                >
                                    <b-card-header>
                                        <b-card-title>Units</b-card-title>
                                    </b-card-header>

                                    <b-card-body style="max-height: 350px; overflow-y: auto;">
                                        <div class="transaction-item">
                                            <b-media no-body>
                                                <b-media-aside>
                                                    <b-avatar
                                                    rounded
                                                    size="42"
                                                    :variant="'light-primary'"
                                                    >
                                                    <feather-icon
                                                        size="18"
                                                        :icon="'TruckIcon'"
                                                    />
                                                    </b-avatar>
                                                </b-media-aside>
                                                <b-media-body>
                                                    <h6 class="transaction-title bold">
                                                        Total Units
                                                    </h6>
                                                </b-media-body>
                                            </b-media>
                                            <div class="font-weight-bolder" >
                                                    {{ dataUnits.totalUnits }}
                                            </div>
                                        </div>
                                        
                                        <div
                                            v-for="unit in dataUnits.detailUnits"
                                            :key="unit.mode"
                                            class="transaction-item"
                                        >
                                            <b-media no-body>
                                                <b-media-aside>
                                                    <b-avatar
                                                        rounded
                                                        size="42"
                                                        :variant="'light-primary'"
                                                        >
                                                        <feather-icon
                                                            size="18"
                                                            :icon="'TruckIcon'"
                                                        />
                                                    </b-avatar>
                                                </b-media-aside>
                                                <b-media-body>
                                                    <h6 class="transaction-title">
                                                        {{ unit.type }}
                                                    </h6>
                                                    <small>Weight : {{ unit.weight_cap }} Kg, Volume : {{ unit.volume_cap }} M<sup>3</sup></small>
                                                </b-media-body>
                                            </b-media>
                                            <div class="font-weight-bolder">
                                                    {{ unit.value }}
                                            </div>
                                        </div>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                            
                            <b-col md="8">
                                <b-card
                                        no-body
                                        class="card-browser-states"
                                    >
                                    <b-card-header>
                                        <b-card-title>Unit Out Of Service</b-card-title>
                                    </b-card-header>
                                    <b-card-body style="max-height: 350px; overflow-y: auto;">
                                        <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                            <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefreshServiceOrder()">{{ btnText.refresh }}</LinkButton>
                                            <span class="button-sep"></span>&nbsp;&nbsp;{{ search.text }} : 
                                            <SearchBox style="width:300px"
                                                :placeholder="search.placeholder" 
                                                v-model="dataGrid.search"
                                                @search="onSearchFile()">
                                                <Addon>
                                                    <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearchFile();"></span>
                                                </Addon>
                                            </SearchBox>
                                        </div>
                                        <DataGrid
                                                ref="dataGrid"
                                                :columnResizing="true"
                                                :border="false"
                                                :lazy="true"
                                                :loading="dataGrid.loading"                                    
                                                :data="dataGrid.rows"
                                                :pagination="true"
                                                :total="dataGrid.total"
                                                :pageSize="dataGrid.pageSize"
                                                :pageNumber="dataGrid.pageNumber"
                                                :pageLayout="dataGrid.pageLayout" 
                                                :footerData="dataGrid.footerData"
                                                :showFooter="true"
                                                @pageChange="onPageChange($event)"
                                                @sortChange="onSortChange($event)"
                                                @rowExpand="onRowExpand($event)"
                                                >
                                            <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                                                <template slot="body" slot-scope="scope">
                                                    {{scope.rowIndex + 1}}
                                                </template>
                                            </GridColumn>
                                            <GridColumn :expander="true" width="30"></GridColumn>

                                            <GridColumn field="service_order_no" title="Service Id" :sortable="true"></GridColumn>
                                            <GridColumn field="vehicle_desc" title="Vehicle Id" :sortable="true"></GridColumn>
                                            <GridColumn field="registered_date_desc" title="Registered Date" :sortable="true"></GridColumn>
                                            <GridColumn field="completion_date_desc" title="Completion Date" :sortable="true"></GridColumn>
                                            <GridColumn field="total_amount" title="Total Amount" :sortable="true" align="right"></GridColumn>
                                            <GridColumn field="workshop_desc" title="Workshop Id" :sortable="true"></GridColumn>
                                            <GridColumn field="service_type_desc" title="Service Type" :sortable="true"></GridColumn>
                                            <GridColumn field="service_status_desc" title="Service Status" :sortable="true"></GridColumn>
                                            <template slot="detail" slot-scope="scope">
                                                <div style="padding:2px 2px 2px 30px">
                                                    <DataGrid :data="scope.row.subData" :showFooter="true" style="width: 80%;" :footerData="scope.row.subData.footerData">
                                                        <GridColumn field="component_desc" title="Service Task" width="150"></GridColumn>
                                                        <GridColumn field="qty" title="Qty"  width="50" align="right"></GridColumn>
                                                        <GridColumn field="price" title="Price" width="80" align="right"></GridColumn>
                                                        <GridColumn field="amount" title="Amount" width="80" align="right"></GridColumn>
                                                    </DataGrid>
                                                </div>
                                            </template>
                                        </DataGrid>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-container>
                </LayoutPanel>
            </Layout>
            <!-- <Layout style="margin : 10px 0px;">
                <LayoutPanel>
                    <b-container fluid>
                        <b-row>
                            <b-col sm="6" class="mt-2">
                                <div class="title">Shipment activity</div>
                                <div class="content-chart" >
                                    <div ref="chartShipmentActual" class="chart-data" style="min-height: 450px;"></div>
                                </div>
                            </b-col>
                            <b-col sm="2" class="mt-2">
                                <div class="title">Traffic Monitoring stats</div>
                                <div class="content-traffic">
                                    <div class="item-traffic">
                                        <div class="text-traffic">
                                            <div class="title-traffic"> <b>{{dataTrafficMonitoring.pickup}}</b> Pickup Order</div>
                                            <div class="percent-traffic">
                                                {{dataTrafficMonitoring.pickupPercent}} %
                                            </div>
                                        </div>
                                        <div style="clear:both;"></div>
                                        <ProgressBar :value="dataTrafficMonitoring.pickupPercent" class="progress-pickup"></ProgressBar>
                                    </div>
                                    <div class="item-traffic">
                                        <div class="text-traffic">
                                            <div class="title-traffic"> <b>{{dataTrafficMonitoring.loading}}</b> Loading</div>
                                            <div class="percent-traffic">
                                                {{dataTrafficMonitoring.loadingPercent}} %
                                            </div>
                                        </div>
                                        <div style="clear:both;"></div>
                                        <ProgressBar :value="dataTrafficMonitoring.loadingPercent" class="progress-loading"></ProgressBar>
                                    </div>
                                    <div class="item-traffic">
                                        <div class="text-traffic">
                                            <div class="title-traffic"> <b>{{dataTrafficMonitoring.finishLoading}}</b> Finish Loading</div>
                                            <div class="percent-traffic">
                                                {{dataTrafficMonitoring.finishLoadingPercent}} %
                                            </div>
                                        </div>
                                        <div style="clear:both;"></div>
                                        <ProgressBar :value="dataTrafficMonitoring.finishLoadingPercent" class="progress-finish-loading"></ProgressBar>
                                    </div>
                                    <div class="item-traffic">
                                        <div class="text-traffic">
                                            <div class="title-traffic"> <b>{{dataTrafficMonitoring.arrived}}</b> Arrived</div>
                                            <div class="percent-traffic">
                                                {{dataTrafficMonitoring.arrivedPercent}} %
                                            </div>
                                        </div>
                                        <div style="clear:both;"></div>
                                        <ProgressBar :value="dataTrafficMonitoring.arrivedPercent" class="progress-pickup"></ProgressBar>
                                    </div>
                                    <div class="item-traffic">
                                        <div class="text-traffic">
                                            <div class="title-traffic"> <b>{{dataTrafficMonitoring.unloading}}</b> Unloading</div>
                                            <div class="percent-traffic">
                                                {{dataTrafficMonitoring.unloadingPercent}} %
                                            </div>
                                        </div>
                                        <div style="clear:both;"></div>
                                        <ProgressBar :value="dataTrafficMonitoring.unloadingPercent" class="progress-loading"></ProgressBar>
                                    </div>
                                    <div class="item-traffic">
                                        <div class="text-traffic">
                                            <div class="title-traffic"> <b>{{dataTrafficMonitoring.finishUnloading}}</b> Finish Unloading</div>
                                            <div class="percent-traffic">
                                                {{dataTrafficMonitoring.finishUnloadingPercent}} %
                                            </div>
                                        </div>
                                        <div style="clear:both;"></div>
                                        <ProgressBar :value="dataTrafficMonitoring.finishUnloadingPercent" class="progress-finish-loading"></ProgressBar>
                                    </div>
                                </div>
                            </b-col>
                            <b-col sm="4" class="mt-2">
                                <div class="title">Trucking Order status</div>
                                <div class="content-chart">
                                    <div ref="chartTruckingOrderStatus" class="chart-data" style="min-height: 450px; padding: 50px"></div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-container>

                    <b-container fluid>
                        <b-row>
                            <b-col sm="4">
                                <b-row>
                                    <b-col class="mt-2">
                                        <b-card>
                                            <b-card-body class="item-card"> 
                                                <div class="value-card"><b>{{dataCost.totalCost}}</b></div>
                                                <div class="title-card">Total Cost</div> 
                                            </b-card-body>
                                        </b-card>  
                                    </b-col>
                                </b-row>
                                <b-row class="mt-2">
                                    <b-col>
                                        <b-card>
                                            <b-card-body class="item-card"> 
                                                <div class="value-card"><b>{{dataCost.totalRevenue}}</b></div>
                                                <div class="title-card">Total Revenue</div>
                                            </b-card-body>
                                        </b-card>  
                                    </b-col>
                                </b-row>
                                <b-row class="mt-2">
                                    <b-col>
                                        <b-card style="max-height: 490px; overflow-y: auto;">
                                            <b-card-header>
                                                <div class="title-data">Units</div>
                                            </b-card-header>
                                            <b-card-body>
                                                <div class="content-data" >
                                                    <div class="item-units">
                                                        <div class="title-units" style="font-weight: bold;">Total Units</div>
                                                        <div class="value-units"><b>{{dataUnits.totalUnits}}</b></div>
                                                    </div>

                                                    <div class="item-units" v-for="(val, idx) in dataUnits.detailUnits" v-bind:key="idx">
                                                        <div class="title-units">{{val.type}}</div>
                                                        <div class="value-units"><b>{{val.value}}</b></div>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col sm="8">
                                <b-row>
                                    <b-col sm="4" class="mt-2">
                                        <b-card bg-variant="warning" style="color: #fff;">
                                            <b-card-body class="item-card">
                                                <div class="value-card"><b>{{dataDocument.totalPod}}</b></div>
                                                <div class="title-card">Finish POD</div>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                    <b-col sm="4" class="mt-2">
                                        <b-card bg-variant="danger" style="color: #fff;">
                                            <b-card-body class="item-card">
                                                <div class="value-card"><b>{{dataDocument.totalPendingPod}}</b></div>
                                                <div class="title-card">Pending POD</div>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                    <b-col sm="4" class="mt-2">
                                        <b-card bg-variant="success" style="color: #fff;">
                                            <b-card-body class="item-card">
                                                <div class="value-card"><b>{{dataDocument.totalShipment}}</b></div>
                                                <div class="title-card">Total Shipment</div>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-2">
                                    <b-col>
                                        <Layout>
                                            <LayoutPanel region="center" class="layout-full" :bodyStyle="{borderRadius : '5px'}">
                                                <div class="title-data p-2">Unit Out Of Service</div>
                                                <Panel style="width : 100%">
                                                    <div class="datagrid-toolbar" style="margin-bottom: 10px;">
                                                        <LinkButton :plain="true" :iconCls="iconUtil.iconRefresh" @click="onRefreshServiceOrder()">{{ btnText.refresh }}</LinkButton>
                                                        <span class="button-sep"></span>&nbsp;&nbsp;{{ search.text }} : 
                                                        <SearchBox style="width:300px"
                                                            :placeholder="search.placeholder" 
                                                            v-model="dataGrid.search"
                                                            @search="onSearchFile()">
                                                            <Addon>
                                                                <span v-if="dataGrid.search" class="textbox-icon icon-clear" title="Clear value" @click="dataGrid.search=null;onSearchFile();"></span>
                                                            </Addon>
                                                        </SearchBox>
                                                    </div>
                                                    <DataGrid
                                                            ref="dataGrid"
                                                            :columnResizing="true"
                                                            :border="false"
                                                            :lazy="true"
                                                            :loading="dataGrid.loading"                                    
                                                            :data="dataGrid.rows"
                                                            :pagination="true"
                                                            :total="dataGrid.total"
                                                            :pageSize="dataGrid.pageSize"
                                                            :pageNumber="dataGrid.pageNumber"
                                                            :pageLayout="dataGrid.pageLayout" 
                                                            :footerData="dataGrid.footerData"
                                                            :showFooter="true"
                                                            @pageChange="onPageChange($event)"
                                                            @sortChange="onSortChange($event)"
                                                            @rowExpand="onRowExpand($event)"
                                                            >
                                                        <GridColumn align="center" cellCss="datagrid-td-rownumber" width="30">
                                                            <template slot="body" slot-scope="scope">
                                                                {{scope.rowIndex + 1}}
                                                            </template>
                                                        </GridColumn>
                                                        <GridColumn :expander="true" width="30"></GridColumn>

                                                        <GridColumn field="service_order_no" title="Service Id" :sortable="true"></GridColumn>
                                                        <GridColumn field="vehicle_desc" title="Vehicle Id" :sortable="true"></GridColumn>
                                                        <GridColumn field="registered_date_desc" title="Registered Date" :sortable="true"></GridColumn>
                                                        <GridColumn field="completion_date_desc" title="Completion Date" :sortable="true"></GridColumn>
                                                        <GridColumn field="total_amount" title="Total Amount" :sortable="true" align="right"></GridColumn>
                                                        <GridColumn field="workshop_desc" title="Workshop Id" :sortable="true"></GridColumn>
                                                        <GridColumn field="service_type_desc" title="Service Type" :sortable="true"></GridColumn>
                                                        <GridColumn field="service_status_desc" title="Service Status" :sortable="true"></GridColumn>
                                                        <template slot="detail" slot-scope="scope">
                                                            <div style="padding:2px 2px 2px 30px">
                                                                <DataGrid :data="scope.row.subData" :showFooter="true" style="width: 80%;" :footerData="scope.row.subData.footerData">
                                                                    <GridColumn field="component_desc" title="Service Task" width="150"></GridColumn>
                                                                    <GridColumn field="qty" title="Qty"  width="50" align="right"></GridColumn>
                                                                    <GridColumn field="price" title="Price" width="80" align="right"></GridColumn>
                                                                    <GridColumn field="amount" title="Amount" width="80" align="right"></GridColumn>
                                                                </DataGrid>
                                                            </div>
                                                        </template>
                                                    </DataGrid>
                                                </Panel>                            
                                            </LayoutPanel>
                                        </Layout>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-container>
                </LayoutPanel>
            </Layout> -->
        </Panel>    
    </div>
</template>

<script>
import GlobalUtil from '@/utils/GlobalUtil';
import IconUtil from '@/utils/IconUtil';
import InformationUtil from '@/utils/InformationUtil';

import AppService from '@/services/AppService';

// import chart
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import ModelUtil from '@/utils/ModelUtil';

am4core.useTheme(am4themes_kelly);

import VueApexCharts from 'vue-apexcharts'

export default {
    components: {
        VueApexCharts
    },
    name: 'DashboardView',
    data() {
        return {
            full_name : '',
            shipmentByType: {
                totalData : 0,
                series: [],
                chartOptions : { 
                    legend: { show: false },
                    chart: {
                        // height: 350,
                        type: 'bar',
                        toolbar: { show: false },
                    },
                    colors: ['#5349b0'],
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return val;
                        },
                        offsetY: -20,
                        style: {
                            fontSize: '12px',
                            colors: ["#304758"]
                        }
                    },
                    xaxis: {
                        categories: [],
                        position: 'bottom',
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        crosshairs: {
                            fill: {
                                type: 'gradient',
                                gradient: {
                                    colorFrom: '#D8E3F0',
                                    colorTo: '#BED1E6',
                                    stops: [0, 100],
                                    opacityFrom: 0.4,
                                    opacityTo: 0.5,
                                }
                            }
                        },
                        tooltip: {
                            enabled: true,
                        }
                    },
                    yaxis: {
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: false,
                            formatter: function (val) {
                                return val;
                            }
                        }
                    },
                    grid: {
                        show: true,
                        xaxis: {
                            lines: {
                                show: false
                            }
                        },  
                        yaxis: {
                            lines: { 
                                show: false
                            }
                        },   
                    },
                }
            },

            truckingData: {
                series: [0],
                chartOptions: {
                    plotOptions: {
                        radialBar: {
                            size: 150,
                            offsetY: 20,
                            startAngle: -150,
                            endAngle: 150,
                            hollow: {
                                size: '65%',
                            },
                            track: {
                                background: '#fff',
                                strokeWidth: '100%',
                            },
                            dataLabels: {
                                name: {
                                    offsetY: -5,
                                    color: '#5e5873',
                                    fontSize: '1rem',
                                },
                                value: {
                                    offsetY: 15,
                                    color: '#5e5873',
                                    fontSize: '1.714rem',
                                },
                            },
                        },
                    },
                    colors: ['#F97794'],
                    fill: {
                        type: 'gradient',
                        gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5,
                        gradientToColors: ['#9C27B0'],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100],
                        },
                    },
                    stroke: {
                        dashArray: 8,
                    },
                    labels: ['Completed'],
                },
            },

            truckingOrderStatus: {
                totalData : 0,
                open : 0,
                close : 0,
                series: [0],
                chartOptions: {
                    plotOptions: {
                        radialBar: {
                            size: 150,
                            offsetY: 20,
                            startAngle: -150,
                            endAngle: 150,
                            hollow: {
                                size: '65%',
                            },
                            track: {
                                background: '#fff',
                                strokeWidth: '100%',
                            },
                            dataLabels: {
                                name: {
                                    offsetY: -5,
                                    color: '#5e5873',
                                    fontSize: '1rem',
                                },
                                value: {
                                    offsetY: 15,
                                    color: '#5e5873',
                                    fontSize: '1.714rem',
                                },
                            },
                        },
                    },
                    colors: ['#F97794'],
                    fill: {
                        type: 'gradient',
                        gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5,
                        gradientToColors: ['#0000ff'],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100],
                        },
                    },
                    stroke: {
                        dashArray: 8,
                    },
                    labels: ['Completed'],
                },
            },

            truckingDeliveryStatus: {
                totalData : 0,
                onGoing : 0,
                delivered : 0,
                series: [0],
                chartOptions: {
                    plotOptions: {
                        radialBar: {
                            size: 150,
                            offsetY: 20,
                            startAngle: -150,
                            endAngle: 150,
                            hollow: {
                                size: '65%',
                            },
                            track: {
                                background: '#fff',
                                strokeWidth: '100%',
                            },
                            dataLabels: {
                                name: {
                                    offsetY: -5,
                                    color: '#5e5873',
                                    fontSize: '1rem',
                                },
                                value: {
                                    offsetY: 15,
                                    color: '#5e5873',
                                    fontSize: '1.714rem',
                                },
                            },
                        },
                    },
                    colors: ['#4caf50'],
                    fill: {
                        type: 'gradient',
                        gradient: {
                        shade: 'dark',
                        type: 'horizontal',
                        shadeIntensity: 0.5,
                        gradientToColors: ['#4caf50'],
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100],
                        },
                    },
                    stroke: {
                        dashArray: 8,
                    },
                    labels: ['Completed'],
                },
            },

            titleView: 'Dashboard',
            urlAccess : 'dashboard',
            iconUtil : IconUtil,
            filter: {
                fromDate: {
                    text: 'From',
                    value: new Date()
                },
                toDate: {
                    text: 'To',
                    value: new Date()
                }
            },
            btnText: {
                refresh: InformationUtil.refreshText
            },
            useDashboard : false,

            dataChartShipmentActual : [],
            dataChartTruckingOrder : [],
            dataTrafficMonitoring : {
                pickup : 0,
                loading : 0,
                finishLoading : 0,
                arrived : 0,
                unloading : 0,
                finishUnloading : 0,
                pickupPercent : 0.0,
                loadingPercent : 0.0,
                finishLoadingPercent : 0.0,
                arrivedPercent : 0.0,
                unloadingPercent : 0.0,
                finishUnloadingPercent : 0.0,
            },
            dataCost : {
                totalCost : "IDR 0",
                totalRevenue : "IDR 0"
            },
            dataUnits : {
                totalUnits : 0,
                detailUnits : []
            },
            dataDocument : {
                totalShipment : 0,
                totalPendingPod : 0,
                totalPod : 0,
                totalAllPod : 0,
                percentPod : 0.0
            },

            search : {
                text : InformationUtil.searching,
                placeholder : InformationUtil.searchHolder
            },

            dataGrid : {
                loading: false,
                rows : [],
                total: 0,
                start: 0,
                pageNumber: 1,
                pageSize: 10,
                selectionMode : 'single',
                pageLayout: ['list','sep','first','prev','next','last','sep','refresh','info'],

                // filter
                search : null,
                sortField : "",
                sortOrder : "",

                // footer
                footerData : [
                    { completion_date_desc: "Total:", total_amount: 0 }
                ]
            },
        }
    },
    methods: {
        async onRefresh() {
            if(this.useDashboard)
            {
                await this.onLoadData();
            }
        },
        onLoadChartShipmentActual(){
            let chart = am4core.create(this.$refs.chartShipmentActual, am4charts.XYChart);
            chart.data = this.dataChartShipmentActual;
            let title = chart.titles.create();
            title.text = "Period : from "+this.$moment(this.filter.fromDate.value).format('LL')+" to " + this.$moment(this.filter.toDate.value).format('LL');
            
            title = chart.titles.create();
            title.text = "Shipment Activity by Type";
            title.fontSize = 12;
            title.fontWeight = "bold";

            var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "type";
            categoryAxis.renderer.labels.template.rotation = 320;

            var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.title.text = "Shipment Activity by Type";
            valueAxis.title.fontWeight = 800;

            var series = chart.series.push(new am4charts.ColumnSeries());

            let colorSet = new am4core.ColorSet();
            series.columns.template.adapter.add("fill", function(fill, target) {
                return colorSet.getIndex(target.dataItem.index);
            });
            series.dataFields.valueY = "value";
            series.dataFields.categoryX = "type";
            series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";

            var bullet = series.bullets.push(new am4charts.LabelBullet());
            bullet.label.text =  "[bold]{valueY}[/]";
            bullet.label.fill = am4core.color("#000");
        },
        onLoadChartTruckingOrderStatus(){
            let chart = am4core.create(this.$refs.chartTruckingOrderStatus, am4charts.PieChart);
            chart.legend = new am4charts.Legend;
            chart.data = this.dataChartTruckingOrder;

            // Add and configure Series
            let pieSeries = chart.series.push(new am4charts.PieSeries());
            pieSeries.colors.list = [
                am4core.color("#67b7dc"),
                am4core.color("#fdd400"),
            ];
            pieSeries.dataFields.value = "value";
            pieSeries.dataFields.category = "type";
        },

        // get data
        async getShipmentActual(){
            AppService.post(this.urlAccess + '/shipmentActual', this.getParam())
            .then((response) => {
                if(response.code == 200){
                    this.dataChartShipmentActual = response.data;
                    this.shipmentByType.chartOptions.xaxis.categories = [];
                    this.shipmentByType.series = [];
                    this.shipmentByType.totalData = 0;
                    let series = {
                        data: []
                    };
                    this.dataChartShipmentActual.forEach(element => {
                        this.shipmentByType.totalData += element.value;
                        series.data.push(element.value); 
                        this.shipmentByType.chartOptions.xaxis.categories.push(element.type == null ? "-" : element.type);
                    });
                    this.shipmentByType.series.push(series);
                    this.$refs.shipmentByTypeChart.updateOptions(this.shipmentByType.chartOptions);

                    //this.onLoadChartShipmentActual();
                }
            });
        },

        async getTruckingOrder(){
            AppService.post(this.urlAccess + '/truckingOrder', this.getParam())
            .then((response) => {
                if(response.code == 200){
                    this.dataChartTruckingOrder = response.data;
                    let dataOpen = 0;
                    let dataClose = 0;
                    let totalData = 0;
                    this.dataChartTruckingOrder.forEach(element => {
                        totalData += element.value;
                        if(element.type == "OPEN"){
                            dataOpen = element.value;
                        }

                        if(element.type == "CLOSE"){
                            dataClose = element.value;
                        }
                    });

                    this.truckingOrderStatus.totalData = totalData;
                    this.truckingOrderStatus.open = dataOpen;
                    this.truckingOrderStatus.close = dataClose;
                    let percent = 0;
                    if(dataClose > 0) {
                        percent = parseInt((dataClose / totalData) * 100);
                    }
                    this.truckingOrderStatus.series = [percent];

                    //this.onLoadChartTruckingOrderStatus();
                }
            });
        },

        async getTrafficMonitoring(){
            AppService.post(this.urlAccess + '/trafficMonitoring', this.getParam())
            .then((response) => {
                if(response.code == 200){
                    this.dataTrafficMonitoring = ModelUtil.resetModel(this.dataTrafficMonitoring);
                    this.dataTrafficMonitoring = ModelUtil.mapModel(this.dataTrafficMonitoring, response.data);
                }
            });
        },

        async getCost(){
            AppService.post(this.urlAccess + '/cost', this.getParam())
            .then((response) => {
                if(response.code == 200){
                    this.dataCost = response.data;
                }
            });
        },

        async getVehicle(){
            AppService.post(this.urlAccess + '/vehicle', this.getParam())
            .then((response) => {
                if(response.code == 200){
                    this.dataUnits.totalUnits = response.data.totalVehicle;
                    this.dataUnits.detailUnits = response.data.details;
                }
            });
        },

        async getDocument(){
            AppService.post(this.urlAccess + '/document', this.getParam())
            .then((response) => {
                if(response.code == 200){
                    this.dataDocument = response.data;
                    // set percent trucking data
                    this.truckingData.series = [this.dataDocument.percentPod];
                }
            });
        },

        async getDeliveryStatus(){
            AppService.post(this.urlAccess + '/deliveryStatus', this.getParam())
            .then((response) => {
                if(response.code == 200){
                    const responseData = response.data;
                    let dataOnGoing = 0;
                    let dataDelivered = 0;
                    let totalData = 0;
                    responseData.forEach(element => {
                        totalData += element.value;
                        if(element.type == "DELIVERED"){
                            dataDelivered += element.value;
                        }

                        if(element.type == "ON_GOING"){
                            dataOnGoing += element.value;
                        }
                    });

                    this.truckingDeliveryStatus.totalData = totalData;
                    this.truckingDeliveryStatus.onGoing = dataOnGoing;
                    this.truckingDeliveryStatus.delivered = dataDelivered;
                    let percent = 0;
                    if(dataDelivered > 0) {
                        percent = parseInt((dataDelivered / totalData) * 100);
                    }
                    this.truckingDeliveryStatus.series = [percent];
                }
            });
        },

        onRowExpand(row){
            if ( ! row.subData ){
                row.subData = JSON.parse(row.task_entries);
                row.subData.footerData = [
                    { price: "Sub Total:", amount: row.sub_total },
                    { price: "Total VAT:", amount: row.total_vat },
                    { price: "Total Amount:", amount: row.total_amount }
                ];
            }
        },

        async onRefreshServiceOrder(){
            this.dataGrid.pageNumber = 1;       
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            await this.loadData();
            this.$refs.notification.success(InformationUtil.refresh);
        },
        onSearch(){
            this.loadData();
        },
        onSortChange(event){
            if(event.length > 0){
                this.dataGrid.pageNumber = 1;
                this.dataGrid.sortField = event[0].field;
                this.dataGrid.sortOrder = event[0].order;
                this.loadData();
            }
        },
        onPageChange(event) {
            this.$refs.dataGrid.unselectRow(this.$refs.dataGrid.selectedRows[0]);
            this.dataGrid.pageNumber = event.pageNumber;
            this.dataGrid.pageSize = event.pageSize;
            if(event.refresh) this.dataGrid.pageNumber = 1;
            this.loadData();
            if(event.refresh){
                this.$refs.notification.success(InformationUtil.refresh);
            }
        },

        async loadData(){
            this.dataGrid.loading = true;
            AppService.loadGrid(this.urlAccess + '/serviceOrder', this.dataGrid)
                .then((response) => {
                    if(response.data !== "" && response.data !== null){
                        let data = response.data; 
                        this.dataGrid.total = data.total;
                        this.dataGrid.rows = data.rows;
                        this.dataGrid.footerData[0].total_amount = data.total_cost;
                    }
                    this.dataGrid.loading = false;
                });
        },

        async onLoadData(){
            await this.$store.dispatch('getUser');
            this.useDashboard = this.$store.getters.user.use_dashboard == "0" ? false : true;
            if(this.useDashboard == 1){
                am4core.disposeAllCharts();
                await this.getShipmentActual();
                await this.getTruckingOrder();
                await this.getTrafficMonitoring();
                await this.getCost();
                await this.getDeliveryStatus();

                this.getVehicle();
                this.getDocument();
                this.loadData();
            }
        },

        getParam(){
            let param = {
                startDate : this.$moment(this.filter.fromDate.value).format('YYYY-MM-DD'),
                endDate : this.$moment(this.filter.toDate.value).format('YYYY-MM-DD')
            }
            return param;
        },
    },
    async mounted() {
        await this.onLoadData();
        // get fullname
        this.full_name = this.$store.getters.user.name;
    },
    created(){
        GlobalUtil.removeWindow();
    }
}
</script>

<style scoped>
.layout-full{
    height: 100%;
}

.title{
    font-size: 12px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.content-chart{
    height: 90%;
    width: 100%;
}

.chart-data{
    height: 100%;
    width: 100%;
}

.content-traffic{
    padding: 10px 0px;
}
.item-traffic{
    margin-bottom: 15px;
}

.text-traffic{
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

.title-traffic{
    width: 80%;
    float: left;
    font-size: 12px;
}

.percent-traffic{
    width: 20%;
    float: left;
    font-size: 12px;
    font-weight: bold;
    text-align: right;
}

/* Card */
.item-card{
    padding : 10px 20px;
}

.value-card {
    font-size: 20px;
    font-weight: bold;
}

.title-card{
    font-size: 12px;
}

.title-data{
    font-size: 12px;
    font-weight: bold;
    color: #333;
    /* padding : 10px; */
}



</style>

<style>

.progressbar {
    height: 7px;
    border-radius: 3px;
    margin-top: 5px;
}

.progress-pickup > .progressbar-value, .progress-pickup > .progressbar-value .progressbar-text {
    background-color: #2196f3 !important;
}

.progress-loading > .progressbar-value, .progress-loading > .progressbar-value .progressbar-text {
    background-color: #c77bd4 !important;
}

.progress-finish-loading > .progressbar-value, .progress-finish-loading > .progressbar-value .progressbar-text {
    background-color: #4caf50 !important;
}

/* units */
.item-units {
    float: left;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    padding: 10px 10px 15px 0px;
}

.title-units {
    font-size: 12px;
    padding: 0px 0px 0px 10px;
    width: 90%;
    float: left;
}

.value-units {
    width: 10%;
    float: left;
}

.content-units .item-units:last-child {
    border-bottom: none;
}

.total-shipment{
    background-color: #ffefa4;
    border-color: #ffd78a;
    color: #574802;
}

.total-pending-pod{
    background-color: #ffc9c9;
    border-color: #ffafbd;
    color: #ac1818;
}

.total-finish-pod{
    background-color: #bdedbc;
    border-color: #b4e8a8;
    color: #045702;
}

.card {
    border: 1px solid rgba(0,0,0,0.125) !important;
    margin-bottom: 2rem !important;
    box-shadow: none !important;
    transition: all .3s ease-in-out, background 0s, color 0s, border-color 0s !important;
}
</style>